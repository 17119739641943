/* home testimony */
.Testimony {
	font-family: $font-2;
	text-align: left;
	text-align: center;
	max-width: 500px;
	margin: 0 auto 30px;
	@include min-lg {
		text-align: left;
	}
	&-picture {
		&-container {
			display: inline-block;
			position: relative;
			border-radius: 14px;
			overflow: hidden;
			text-align: center;
			margin: 0 auto;
		}
	}
	&-header {
		padding-top: 10px;
		padding-bottom: 15px;
	}
	&-link {
		display: inline-block;
		float: left;
		position: relative;
		cursor: pointer;
		img {
			transform: scale(1);
			transition: .3s ease-in-out;
		}
		&::after {
			content: "";
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background: transparent;
			position: absolute;
			transition: ease all 1.5s;
		}
		&::before {
			content: "";
			width: 48px;
			height: 48px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -24px;
			margin-left: -24px;
			z-index: 2;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCI+PGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMjQiIGZpbGw9IiNkNmRjZTEiIG9wYWNpdHk9Ii41IiBzdHJva2Utd2lkdGg9IjAiLz48cGF0aCBkPSJtMTcuNjUsMTMuNzljLS43MS4zOS0xLjE1LDEuMTUtMS4xNSwxLjk2djE2LjVjMCwuODIuNDQsMS41NywxLjE1LDEuOTZzMS41OC4zOCwyLjI4LS4wNWwxMy41LTguMjVjLjY3LS40MSwxLjA4LTEuMTMsMS4wOC0xLjkycy0uNDEtMS41MS0xLjA4LTEuOTJsLTEzLjUtOC4yNWMtLjY5LS40Mi0xLjU3LS40NC0yLjI4LS4wNXYuMDJaIiBmaWxsPSIjNjU3YThlIiBzdHJva2Utd2lkdGg9IjAiLz48L3N2Zz4=")
    }
		&:hover {
			&::after {
				content: "";
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background: rgba(0, 0, 0, 0.5);
				z-index: 1;
				transition: ease all 0.6s;
			}
			img {
				transform: scale(1.1);
			}
		}
	}
	&-author {
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 3px;
	}
	&-office {
		line-height: 1.1;
	}
	&-quote {
		font-weight: 300;
    font-style: italic;
	}
}

/* industry testimony */
.testimonial {
  display: flex;
  align-items: center;
  &__logo {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 1rem;
    @include min-lg {
      padding: 2rem;
    }
  }
  &__text {
    flex: 0 0 70%;
    max-width: 70%;
  }
}