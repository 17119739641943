.footer {
  color: #fff;
  font-family: $font-2;
  margin: 0 auto ;
  text-align: center;
  @include min-md {
    text-align: left;
    padding-left: 7% !important
  }
  @include min-xl {
    padding: 0 20px;
    max-width: 1400px;
  }
  &-container {
    padding: 2rem 0;
    background: $color-dark;
    @include min-lg {
      padding: 4rem 0;
      position: relative;
    }
  }
  &-menu {
    margin-bottom: 20px;
    li {
      margin-bottom: 1rem;
    }
    a {
      display: block;
      font-size: .9rem;
      color: #fff;
      transition: ease all 0.3s;
      &:hover {
        text-indent: 7px;
      }
    }
  }
  &-info {
    &__title {
      font-size: 18px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  &-social {
    display: flex;
    justify-content: center;
    @include min-lg {
      justify-content: inherit;
      display: block;
    }
    > li {
      text-align: center;
      display: inline-flex;
      margin: 0 1rem;
      @include min-lg {
        margin: 1rem;
        display: block;
        clear: both;
        &:first-child {
          margin-top: 0;
        }
      }
      > a {
        img {
          transition: ease all 0.6s;
        }
        svg {
          fill: #fff;
          height: 2rem;
        }
      }
    }
  }
  &-bottom {
    max-width: 1600px;
    margin: 0 auto;
    &-container {
      background: $color-dark;
      display: flex;
      align-items: center;
      padding: 5px 0;
      @include min-lg {
        padding: 30px 0 60px;
      }
    }
    &__copyrights {
      font-size: 12px;
      margin: 0;
      font-weight: 300;
      color: #fff;
      text-align: center;
      @include min-lg {
        font-size: 15px;
      }
      @include max-sm {
        margin: 10px auto;
      }
    }
  }
}
.webmais-telefone {
  font-weight: 900;
  font-size: 18px
}