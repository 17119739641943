.myBtn {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  width: 40%;
  &-left {
    left: 10px;
  }
  &-right {
    right: 10px
  }
  @include min-lg {
    width: 12%;
  }
  cursor: pointer;
  animation: bounce 1.5s ease-in-out forwards;
  animation-iteration-count: infinite;
  &:hover {
    animation: none;
    transform: scale(1.05);
  }
  @keyframes bounce {
    0%  {transform: translateY(0);}
    50% {transform: translateY(10px);}
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}
.modal-content {
  position: relative;
  background-color: $black;
  box-shadow: 0 0 40px 15px rgba(242, 103, 60, .5);
  border-radius: 2rem;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 1rem;
  width: 90%; /* Could be more or less, depending on screen size */
  @include min-lg {
    margin: 10% auto;
    padding: 5rem;
    width: 50%;
  }
}
.close {
  position: absolute;
  top: -20px;
  right: 0;
  color: $orange;
  font-size: 2.7rem;
  font-weight: bold;
  &:hover {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}