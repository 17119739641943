#form-contato {
  #cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-form-joq3m2m5i textarea.bricks-form__input {
    height: 100px!important;
    //padding: 10px 0;
  }
  @include min-md {
    padding: 0 2rem;
  }
}

.row-big-numbers {
  @include max-md {
    margin-bottom: 50px;
  }
}

.col-bubble {
  text-align: center;
  margin-top: 2rem;
  p {
    margin-top: 1.2rem;
    line-height: 1.2;
  }
}

.bubble {
  position: relative;
  background: $color-orange;
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 800;
  line-height: 65px;
  text-align: center;
  width: 100px;
  height: 60px;
  border-radius: 10px;
  padding: 0px;
  margin: 0 auto;
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: $color-orange transparent;
    border-width: 14px 20px 0;
    bottom: -14px;
    left: 50%;
    margin-left: -20px;
  }
}

#linksContato {
  .links-contato-box {
    h2 {
      font-size: 1.4rem;
      font-weight: 700;
    }
    p {
      margin-bottom: 2rem;
    }
  }
  .links-btn {
    max-width: 460px;
    margin-top: 1rem;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 15px 40px;
    @include max-md {
      padding: 15px;
    }
    display: flex;
    align-items: center;
    transition: .2s ease-in;
    &:hover {
      border-color: $orange;
      box-shadow: $box-shadow;
    }
    p {
      margin-bottom: 0;
      @include max-md {
        font-size: .95rem;
      }
      margin-left: 1rem;
    }
  }
}

/*.contato-page {
	margin-top: 100px;
	padding: 80px 0;
	display: inline-block;
	width: 100%;
}
#conversion-form-cta-fale-conosco {
	padding: 40px!important;
    box-shadow: 0 1px 20px #ccc!important;
    border-radius: 40px!important;
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-column-juvacwbp > div, #form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-section-joq3m2m5c  {
    background-color: transparent !important
}
#cta-fale-conosco-a5b70fda95cfc60b45d4 #form-cta-fale-conosco-a5b70fda95cfc60b45d4 #conversion-form-cta-fale-conosco-a5b70fda95cfc60b45d4 section {
    padding: 0 !important
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-form-joq3m2m5i .bricks-form__input {
	border: none!important;
	background: #F4F4F4;
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-form-joq3m2m5i .bricks-form__input {
    border-radius: 20px !important;
    padding-left: 15px !important;
    height: 40px !important
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-form-joq3m2m5i .bricks-form__input.phone {
	width: 100%!important;
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-form-joq3m2m5i textarea.bricks-form__input {
	height: 220px!important;
    padding: 10px 0;
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-form-joq3m2m5i .with-select-flags > .phone-input-group > .phone-country {
	display: none;
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-form-joq3m2m5i .bricks-form__label {
	display: block;
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 {
     color: transparent;
}
#form-cta-fale-conosco-a5b70fda95cfc60b45d4 #rd-button-joq3m2m5a {
    border-radius: 50px !important
}
*/

/*
  .contato {
    background: #f2f2f2;
    padding: 0;
    max-width: 624px;
    min-height: 500px;
    margin: 0 auto;
    position: relative;
    @include min-md {
        box-shadow: 0 2px 5px #ccc;
        border-radius: 30px;
        padding: 25px;
    }
    &-container {
        padding-bottom: 30px;
        @include min-md {
            padding-top: 100px;
        }
    }
    &::before {
        content: "";
        width: 146px;
        height: 232px;
        position: absolute;
        left: -117px;
        top: 20px;
        @include min-md {
            background: url(#{$imagePath}/layout/mascote-formulario-webmais.png) no-repeat center center;
        }
    }
}

.contato-home {
    padding: 0;
    max-width: 960px;
    min-height: 500px;
    margin: 0 auto;
    position: relative;
    background-color: #FFF;
    @include min-md {
        box-shadow: 0 -1px 3px #ccc;
        border-radius: 30px;
    }
    @include min-lg {
        background-repeat: no-repeat;
        background-image: url(#{$imagePath}/layout/contato.webp);
        background-size: contain;
        background-position: right 0;
    }
    &__header {
        text-align: center;
        margin: 50px 0 0;
        > p {
            font-size: 16px;
        }
    }
    &__title {
        font-size: 35px;
        font-weight: 700;
        line-height: 1.1;
        max-width: 300px;
        margin: 0 auto 10px;
    }
    &-form {
        margin: 0 20px;
    }
    &-container {
        padding-bottom: 30px;
        @include min-md {
            //padding-top: 100px;
            //background: linear-gradient(#f7f7f7 50%, $color-dark 50%);
            margin-top: 200px;
            background: linear-gradient(#fff 50%, $color-dark 50%);
        }
    }
}
.webp .contato-home {
    background-image: url(#{$imagePath}/layout/contato.webp);
}
.containerform {
    margin: auto;
    padding: 5% 0 20% 0;
    font-size: 25px;
    text-align: center;
    color: #7d7d7d;
    margin-bottom: 100px
}
.containerformp {
    margin: 50px 0
}
*/
