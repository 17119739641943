//Breakpoints
// Small devices (landscape phones, 576px and up)
@mixin min-sm {
    @media only screen and (min-width: 576px) {
        @content;
    }
}
// Medium devices (tablets, 768px and up)
@mixin min-md {
    @media only screen and (min-width: 768px) {
        @content;
    }
}
// Large devices (desktops, 992px and up)
@mixin min-lg {
    @media only screen and (min-width: 992px) {
        @content;
    }
}
// Extra large devices (large desktops, 1200px and up)
@mixin min-xl {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin min-xxl {
    @media only screen and (min-width: 1400px) {
        @content;
    }
}

@mixin max-sm {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin max-md {
    @media only screen and (max-width: 992px) {
        @content;
    }
}