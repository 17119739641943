$line-height: 1.2;

.btn-wms {
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  display: inline-block;
  transition: $transition;
  padding: 15px 20px;
  background-color: $color-orange;
  min-width: 275px;
  @include max-sm {
    padding: 15px 15px;
    width: 100%;
  }
  &--header {
    font-size: 1rem;
    @include min-lg {
      font-size: 1.15rem;
    }
    padding-top: 20px;
    padding-bottom: 20px;
    &:hover {
      color: #fff;
      background-color: #cc4b00;
    }
  }
  &--white {
    background-color: #fff;
    color: $color-dark;
    &:hover {
      background-color: #e1e5ea;
    }
  }
  &--center {
    margin: 0 auto;
    display: block;
    @include min-lg {
      width: 30%;
    }
  }
}
.col-header-wms {
  position: relative;
  &--img {
    margin: 0 auto;
    @include min-md {
      position: absolute;
      left: 0;
      right: 0;
      top: -14.5rem;
    }
    @include max-sm {
      width: 75%;
      margin-top: 2rem;
    }
  }
}

.wms-title {
  text-align: center;
  color: $color-dark;
  line-height: $line-height;
  h2 {
    font-size: 1.7rem;
    font-weight: 800;
    margin-bottom: 1.2rem;
    span {
      font-weight: 400;
    }
    @include min-lg {
      font-size: 2rem;
    }
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }
  p {
    font-size: 1.12rem;
    line-height: 1.5;
  }
  &-left {
    text-align: left;
  }
  &-orange {
    color: $color-orange;
  }
}

#benefWms {
  padding-top: 5rem;
  @include min-lg {
    padding-bottom: 4rem;
  }
  .benef-box {
    margin-top: 1rem;
    @include min-lg {
      padding: 0 4rem;
      margin-top: 2rem;
    }
    h2 {
      font-size: 1.1rem;
      font-weight: 700;
      margin: 1rem 0;
    }
    &--erp {
      @include min-lg {
        padding: 0;
      }
      .collapsible {
        position: relative;
        background-color: #fff;
        border: 1px solid #fff;
        padding: 30px 30px;
        padding-left: 50px;
        font-size: 1rem;
        font-weight: 700;
        width: 100%;
        text-align: left;
        outline: none;
        border-bottom: none;
        &::before {
          content: '';
          position: absolute;
          background-image: url(#{$imagePath}/icones/plus.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
          left: 15px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
      .content {
        background-color: #fff;
        max-height: 0;
        transition: max-height 0.2s ease-out;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        border-bottom: 2px solid #f8f9fa;
        border-top: none;
        overflow: hidden;
        p {
          padding-left: 2rem;
          padding-right: 2rem;
          padding-bottom: 1rem;
        }
      }
    }
  }
}

#functions {
  padding: 4rem 0 0 0;
  @include min-lg {
    padding: 5rem 0;
  }
}

/* functions tabs */

/* colors */
$red: #FF4A53;
$dark: #333;

$accent: $color-orange;
$accent-inactive: $color-dark;
$secondary: $accent-inactive;


/* tab setting */
$tab-count: 6;
$indicator-width: 50px;
$indicator-height: 4px;

/* breakpoints */
$breakpoints: (
  medium: #{$tab-count*250px},
  small: #{$tab-count*150px}
);


/* selectors relative to radio inputs */
$label-selector: "~ ul > li";
$slider-selector: "~ .slider";
$content-selector: "~ .content > section";

@mixin tabs(
  $label-selector: $label-selector,
  $slider-selector: $slider-selector,
  $content-selector: $content-selector) {
    
  @for $i from 1 through $tab-count {
    &:nth-of-type(#{$i}):checked {
      #{$label-selector}:nth-child(#{$i}) {
        @content;
      }

      #{$slider-selector} {
        transform: translateX(#{100% * ($i - 1)});
      }

      #{$content-selector}:nth-child(#{$i}) {
        display: block;
      }
    }
  }
}

.tabs-functions {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  background: #f8f9fa;
  padding: 50px;
  margin-top: 50px;
  @include max-sm {
    padding: 40px 20px;
  }
  width: 100%;
  //height: 250px;
  border-radius: 5px;
  min-width: #{$tab-count * 60px};
  input[name="tab-control"] {
    display: none;
  }

  /*.content {
    min-height: 400px;
  }*/
  
  .content section h2, ul li label {
    font-family: $font-3;
    font-weight: 600;
    font-size: 14px;
    color: $accent;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    li {
      box-sizing: border-box;
      flex: 1;
      width: #{100%/$tab-count};
      padding: 0 10px;
      @include max-sm {
        padding: 0 4px;
      }
      text-align: center;
      label {
        transition: all 0.3s ease-in-out;
        color: $secondary;
        padding: 5px;
        //overflow: hidden;
        //text-overflow: ellipsis;
        @include min-md {
          display: block;
        }
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        //white-space: nowrap;
        img {
          margin: 0 auto;
        }
        p {
          font-size: 14px;
          margin-top: 10px;
          margin-bottom: 0;
          line-height: $line-height;
          padding: 0 10px;
        }
        -webkit-touch-callout: none;
        @include user-select(none);
        &:hover,
        &:focus,
        &:active {
          outline: 0;
          color: lighten($secondary, 40%);
        }
      }
    }
  }
  
  .slider {
    position: relative;
    width: #{100%/$tab-count};
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
    .indicator {
      position: relative;
      width: $indicator-width;
      max-width: 100%;
      margin: 0 auto;
      height: $indicator-height;
      background: $accent;
      border-radius: 1px;     
    }
  }
  
  .content {
    margin-top: 50px;
    color: $color-dark;
    section {
      display: none;
      animation: {
        name: content;
        direction: normal;
        duration: 0.3s;
        timing-function: ease-in-out;
        iteration-count: 1;
      }
      line-height: $line-height;
      h3 {
        font-size: 2rem;
        font-weight: 800;
        @include max-sm {
          margin-top: 1rem;
          font-size: 1.75rem;
        }
      }
      h4 {
        font-size: 1.1rem;
        font-weight: 600;
        margin: 28px 0;
      }
      p {
        line-height: 1.4;
        padding-right: 2rem;
        @include max-sm {
          margin-bottom: 2rem;
          padding-right: 0;
        }
      }
      a {
        font-weight: 700;
        color: $color-orange;
        text-decoration: underline;
        transition: $transition;
        display: block;
        &:hover {
         filter: brightness(.8);
        }
        @include max-sm {
          margin-bottom: 2rem;
        }
      }
    }
  }
  
  input[name="tab-control"] {
    @include tabs {
      > label {
        cursor: default;
        color: $accent;
        font-weight: 800;
        @media (max-width: map-get($breakpoints, small)) {
          background: rgba(0, 0, 0, 0.08);
        }
      }
    }
  }

  @keyframes content {
    from {
      opacity: 0;
      transform: translateY(5%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  
  @media (max-width: map-get($breakpoints, medium)) {
    ul li label {
      white-space: initial;
      br {
        display: initial;
      }
    }
  }
  
  @media (max-width: map-get($breakpoints, small)) {
    
    ul li label {
      padding: 5px;
      border-radius: 5px;
      p {
        display: none;
      }
    }
    .slider {
      display: none;
    }
    .content {
      margin-top: 20px; 
      section h2 {
        display: block;
      }
    }
  }
}

/* end tabs */

#cta-1 {
  padding: 4rem 0;
  background-color: $color-orange;
  @include min-lg {
    background-image: url(#{$imagePath}/layout/wms/background-cta-wms.svg);
    background-position: 90%;
    background-repeat: no-repeat;
    padding: 5.8rem 0;
  }
  .cta-1-img {
    @include min-lg {
      position: absolute; 
      left: 0;
      right: 0;
      top: 0; 
      margin-left: auto; 
      margin-right: auto;
      margin-top: 0;
      width: 420px; /* Need a specific value to work */;
    }
    margin: 2rem auto 0 auto;
    width: 200px;
    filter: drop-shadow(12px 15px 12px rgba(0, 0, 0, 0.14));
  }
}

.integracoes-mt {
  padding-top: 0!important;
}

#integracoes {
  padding: 4rem 0;
  p {
    line-height: 1.5;
  }
  @include min-lg {
    padding: 7rem 0;
  }
  .list-integracoes {
    li {
      border-left: 2px solid $color-orange;
      padding-left: 1rem;
      margin-bottom: 3.5rem;
      @include min-lg {
        margin-bottom: 5.5rem;
      }
      h3 {
        font-size: 1.28rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
  .img-integracoes {
    text-align: center;
    position: sticky;
    top: 10rem;
    img {
      margin: 0 auto;
      @include min-lg {
        padding: 0 7rem;
      }
    }
    &--texto {
      h3 {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 800;
      }
      h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
}

#cta-2 {
  .cta-2-text {
    padding: 0 1rem 1rem 1rem;
    @include min-lg {
      padding: 4rem 5rem 4rem 0;
    }
    p {
      font-size: 15px;
      line-height: 1.5;
    }
  }
  .wms-title-left {
    padding: 2.5rem 1rem;
    @include min-lg {
      padding: 5rem 5rem 3rem 7rem;
    }
  }
  @include max-sm {
    .no-gutter-img {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include min-lg {
    .row.no-gutter {
      margin-left: 0;
      margin-right: 0;
    }
      
    .row.no-gutter > div[class*="col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .cta-inventario-bkg {
    background-color: #f5f5f5;
    @include min-lg {
      background-image: url(#{$imagePath}/layout/wms/background-cta-wms.svg);
      background-position: -24rem;
      background-repeat: no-repeat;
    }
  }
}

.cta-2-erp {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, $color-dark 50%, rgba(255,255,255,1) 50%);
}
.cta-2-wms {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 50%, $color-dark 50%);
}

#wmsNumbers {
  padding: 4rem 0;
  @include min-lg {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  background: $color-dark;
  .list-bubble {
    display: flex;
    flex-wrap: wrap;
    @include min-lg {
      margin-top: 2.8rem;
    }
    li {
      margin: 0 2rem;
      @include max-sm {
        width: 45%;
        margin: 1rem .5rem;
      }
    }
    .bubble-wms {
      position: relative;
      background: $color-dark;
      color: $color-orange;
      font-size: 2rem;
      font-weight: 800;
      text-align: center;
      width: 150px;
      margin: 0 auto;
      //height: 100px;
      border-radius: 20px;
      padding: 1rem;
      border: #ffffff solid 2px;
      @include max-sm {
        font-size: 2rem;
        border-radius: 10px;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: $color-dark transparent;
        border-width: 20px 30px 0;
        bottom: -20px;
        left: 50%;
        margin-left: -30px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 0;
        z-index: 0;
        border-style: solid;
        border-color: #ffffff transparent;
        border-width: 22px 32px 0;
        bottom: -23px;
        left: 50%;
        margin-left: -32px;
        display: block;
      }
    }
  }
  p {
    text-align: center;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    line-height: $line-height;
    margin-top: 2.4rem;
  }
}

#erpNumbers {
  background: $color-orange;
  @include min-lg {
    background: linear-gradient(180deg, rgba(255,255,255,1) 50%, $color-dark 50%);
  }
  .numbers-box {
    background-color: $color-orange;
    padding: 5rem 0;
  }
  .list-bubble {
    display: flex;
    flex-wrap: wrap;
    @include min-lg {
      margin-top: 2.8rem;
    }
    li {
      margin: 0 2rem;
      @include max-sm {
        width: 45%;
        margin: 1rem .5rem;
      }
    }
    .bubble-wms {
      position: relative;
      background: $color-dark;
      color: $color-orange;
      font-size: 2rem;
      font-weight: 800;
      text-align: center;
      width: 150px;
      margin: 0 auto;
      //height: 100px;
      border-radius: 20px;
      padding: 1rem;
      border: $color-dark solid 2px;
      @include max-sm {
        font-size: 2rem;
        border-radius: 10px;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: $color-dark transparent;
        border-width: 20px 30px 0;
        bottom: -20px;
        left: 50%;
        margin-left: -30px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 0;
        z-index: 0;
        border-style: solid;
        border-color: $color-dark transparent;
        border-width: 22px 32px 0;
        bottom: -23px;
        left: 50%;
        margin-left: -32px;
        display: block;
      }
    }
  }
  p {
    text-align: center;
    color: $color-dark;
    font-weight: 500;
    font-size: 1rem;
    line-height: $line-height;
    margin-top: 2.4rem;
  }
}

#screensHeader {
  padding: 2.5rem 0 1.5rem 0;
  @include min-lg {
    padding: 5rem 0;
  }
}

/* advantage */
.advantage {
  @include min-xxl {
    padding-bottom: 5rem;
  }
  .tabs-wrapper {
    display: flex;
    justify-content: center;
  }
  .tabs {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    transition: all 0.3s ease;
    z-index: 1;
    @include min-lg {
      width: 40%;
    }
    li {
      //margin-left: auto;
      //margin-right: auto;
      margin-bottom: 1.5rem;
      max-width: 350px;
      padding: 15px 35px;
      font-size: .9rem;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      border: solid 1px $color-dark;
      border-radius: 5px;
      color: $color-dark;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        border-color: $color-orange;
        background: $color-orange;
        transition: all 0.3s ease;
      }
    }
  }
}

.advantage .tabs li.tab-active {
  background: $color-orange;
  border-color: $color-orange;
  color: $color-dark;
  transition: all 0.3s ease;
}

.tabs-container {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  z-index: 2;
  /*@include min-lg {
    height: 575px;
    width: 60%;
  }*/
}

.tab-item {
  //padding: 0 15px 15px 15px;
  display: none;
}

.tab-accordion-title {
  display: none;
}

.tab-hide {
  height: 0px;
}

.advantage-img {
  @include max-sm {
    margin-bottom: 2rem;
  }
  img {
    margin: 0 auto;
    padding: 0 10px;
    width: 250px;
  }
}

#slideWms {
  /*.slide-wms:before,
  .slide-wms:after {
    content: "";
    position: absolute;
    //z-index: 0;
    @include min-lg { 
    width: 200px;
    }
    width: 50px;
    top: 0;
    height: 100%;
    pointer-events: none; //makes the links behind clickable.
  }
  .slide-wms:before {
    left: 0;
    background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  }
  .slide-wms:after {
    right: 0;
    background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  }*/
  
  .slick-prev, .slick-next {
    background-color: transparent;
    background-size: contain;
    border: none;
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 30px;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    transition: all .3s ease;
    width: 30px;
    z-index: 5;
  }
  .slick-prev {
    left: -25px;
    @include max-sm {
      left: 0;
    }
    background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmNWYwMDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik01MTIsMjU2QzUxMiwxMTQuNiwzOTcuNCwwLDI1NiwwUzAsMTE0LjYsMCwyNTYsMTE0LjYsNTEyLDI1Niw1MTIsNTEyLDM5Ny40LDUxMiwyNTZaTTI3MSwxMzVhMjMuOTcwOSwyMy45NzA5LDAsMSwxLDMzLjksMzMuOWwtODcsODcsODcsODdBMjMuOTcwOSwyMy45NzA5LDAsMSwxLDI3MSwzNzYuOEwxNjcsMjczYTIzLjkwMSwyMy45MDEsMCwwLDEsMC0zMy45WiIvPjwvc3ZnPg==);
  }
  .slick-next {
    right: -25px;
    @include max-sm {
      right: 0;
    }
    background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmNWYwMDt9PC9zdHlsZT48L2RlZnM+PHBhdGggY2xhc3M9ImEiIGQ9Ik0wLDI1NkMwLDM5Ny40LDExNC42LDUxMiwyNTYsNTEyUzUxMiwzOTcuNCw1MTIsMjU2LDM5Ny40LDAsMjU2LDAsMCwxMTQuNiwwLDI1NlpNMjQxLDM3N2EyMy45NzA5LDIzLjk3MDksMCwwLDEtMzMuOS0zMy45bDg3LTg3LTg3LTg3QTIzLjk3MDksMjMuOTcwOSwwLDAsMSwyNDEsMTM1LjJMMzQ1LDIzOWEyMy45MDEsMjMuOTAxLDAsMCwxLDAsMzMuOVoiLz48L3N2Zz4=);
  }
}

@media screen and (max-width: 786px) {
  .advantage .tabs {
    display: none;
  }
  .tabs-container {
    display: block;
  }
  .tab-accordion-title {
    margin-bottom: 1.4rem;
    padding: 10px 20px;
    display: block;
    font-size: 0.9rem;
    font-weight: 600;
    border: solid 1px $color-dark;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    text-align: center;
    color: $color-dark;
    &:hover {
      border-color: $color-orange;
      background: $color-orange;
      color: $color-dark;
    }
  }
  .tab-accordion-active {
    background: $color-orange;
    color: $color-dark;
  }
  .tab-item {
    padding-top: 15px;
  }
}

/*--*/

.row-nowrap {
  flex-direction: column;
  @include min-lg {
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  position: relative;
}

#migre-wms {
  @include min-lg {
    background-image: url(#{$imagePath}/layout/wms/background-cta-wms.svg);
    background-position: left;
    background-repeat: no-repeat;
    padding: 5.8rem 0 2rem 0;
  }
  .col-left {
    padding: 3rem 1rem;
    h2 {
      font-size: 2rem;
    }
    @include min-lg {
      width: 50%;
      position: absolute;
      left: 0;
      padding: 5rem;
      z-index: 900;
      h2 {
        font-size: 2.4rem;
      }
    }
  }
  .col-right {
    padding: 3rem 1rem;
    @include min-lg {
      width: 58%;
      padding: 10rem;
      padding-top: 12rem;
      padding-right: 5rem;
      z-index: 800;
    }
    ul {
      @include min-lg {
        column-count: 2;
      }
      li {
        img {
          margin-right: .5rem;
        }
        a {
          display: inline-block;
          color: $color-orange;
          transition: 0.2s ease-in-out;
          &:hover {
            margin-left: .5rem;
          }
        }
        color: $color-orange;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 2rem;
      }
    }
  }
  .col-right--flex {
    display: flex;
    padding: 3rem 1rem;
    @include min-lg {
      width: 58%;
      padding: 10rem;
      padding-top: 10rem;
      padding-right: 5rem;
      z-index: 800;
    }
    ul {
      &:nth-child(1) {
        margin-right: 1rem;
        @include min-lg {
          margin-right: 4rem;
        }
      }
      h3 {
        font-size: 1rem;
        @include min-lg {
          font-size: 1.4rem;
        }
        color: $color-orange;
        margin-bottom: 2rem;
        font-weight: 700;
      }
      li {
        img {
          margin-right: .5rem;
        }
        a {
          display: inline-block;
          color: #fff;
          transition: 0.2s ease-in-out;
          position: relative;
          padding-left: 15px;
          &::before {
            content: '';
            position: absolute;
            background: url(#{$imagePath}/icones/arrow-list.svg) no-repeat;
            width: 15px;
            height: 15px;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);

          }
          &:hover {
            color: $color-orange;
            text-decoration: underline;
          }
        }
        font-size: .9rem;
        @include min-lg {
          font-size: 1rem;
        }
        font-weight: 500;
        margin-bottom: 1.4rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .col-orange {
    background-color: $color-orange;
    @include min-lg {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }
  .col-black  {
    background-color: $color-dark;
  }
}

#downloads {
  .row-downloads {
    background-color: $color-orange;
    padding: 4rem 0;
  }
  ul {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    //flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    @include min-lg {
      flex-direction: row;
      align-items: flex-start;
    }
    li {
      margin-bottom: 2rem;
      @include min-lg {
        margin: 0 1rem;
      }
      text-align: center;
      width: fit-content;
      figure {
        border: 5px solid #fff;
        position: relative;
        background-color: $color-dark;
        .icon-link-wms {
          position: absolute;
          top: 8%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          opacity: 0;
          width: 40px;
          height: 40px;
        }
        &:hover {
          .icon-link-wms {
            top: 50%;
            opacity: 1;
            transition: all .2s ease-in-out;
          }
          .img-link-wms {
            opacity: .5;
            transition: all .2s ease-in-out;
          }
        }
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        font-size: .8rem;
        color: #fff;
        padding: 5px 1rem;
        text-align: center;
      }
      .selo-azul {
        background-color: #5f00ff;
      }
      .selo-verde {
        background-color: #25d364;
      }
      .selo-rosa {
        background-color: #9c00ff;
      }
      p {
        margin-top: .8rem;
        font-weight: 600;
        line-height: 1.2;
      }
    }
  }
}
@include min-lg {
  .downloads-wms {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 50%, $color-dark 50%);
  }
}

.downloads-erp {
  background-color: $color-orange;
  padding: 4rem 0;
  @include min-lg {
    padding: 5rem 0;
    background-color: $color-orange;
    background-image: url(#{$imagePath}/layout/wms/background-cta-wms.svg);
    background-position: right 10rem;
    background-repeat: no-repeat;
  }
}

.sobre-webmais-background {
  @include min-lg {
    background-image: url(#{$imagePath}/layout/background-vetor-orange.svg);
    background-position: right;
    background-repeat: no-repeat;
  }
}

#sobreWebmais {
  background-color: $color-dark;
  padding: 4rem 0;
  @include min-lg {
    padding-top: 10rem;
    padding-bottom: 7rem;
  }
  @include min-lg {
    .wms-img-sobre {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0 2rem;
    }
  }
  .wms-title-sobre {
    margin-bottom: 4rem;
    @include min-lg {
      margin-right: 5rem;
      margin-bottom: 0;
    }
    h2 {
      font-size: 3rem;
      color: $color-orange;
    }
    p {
      color: #fff;
    }
  }
}

#ctaWebmais {
  padding: 5rem 0;
  @include min-lg {
    padding: 10rem 0;
  }
  background-image: url(#{$imagePath}/layout/wms/background-sobre-webmais.webp);
  background-repeat: no-repeat;
  .wms-title {
    h2 {
      font-size: 2.5rem;
      @include min-lg {
        font-size: 3rem;
      }
    }
    p {
      color: #fff;
    }
  }
}

#wmsForm {
  @include min-lg {
    margin-top: 7rem;
  }
  .wms-title-left {
    padding-top: 5rem;
    padding-bottom: 2rem;
    padding-right: 4rem;
    @include min-lg {
      padding: 5rem;
    }
  }
  .wmsform-bkg {
    background-color: #f8f9fa;
    padding-bottom: 5rem;
    @include min-lg {
      background-image: url(#{$imagePath}/layout/wms/wms-form-bkg.webp);
      background-position: -50%, -50%;
      background-repeat: no-repeat;
      padding-bottom: 0;
    }
  }
  .BannerHeader__form {
    max-width: 100%;
    @include min-lg {
      padding: 0 2rem;
    }
  }
}

.formulario {
  min-height: 320px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#cta-erp {
  .cta-2-text {
    padding: 0 1rem 1rem 1rem;
    @include min-lg {
      padding: 4rem 5rem 4rem 0;
    }
    p {
      font-size: 15px;
      line-height: 1.5;
    }
  }
  .wms-title-left {
    padding: 2.5rem 1rem;
    @include min-lg {
      padding: 5rem 5rem 3rem 7rem;
    }
  }
  @include max-sm {
    .no-gutter-img {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include min-lg {
    .row.no-gutter {
      margin-left: 0;
      margin-right: 0;
    }
      
    .row.no-gutter > div[class*="col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .cta-erp-bkg {
    background-color: $color-orange;
    @include min-lg {
      background-image: url(#{$imagePath}/layout/wms/background-cta-wms.svg);
      background-position: 55rem;
      background-repeat: no-repeat;
    }
  }
}

#integracoesERP {
  padding: 5rem 0;
  @include min-lg {
    padding: 10rem 0;
  }
  .integracoes-erp-list {
    display: flex;
    margin: 4rem 0;
    @include max-sm {
      flex-wrap: wrap;
      margin: 2rem 0;
      li {
        width: 50%;
      }
    }
  }
}

#app-vendas {
  background: $color-bright;
  @include min-lg {
    background: linear-gradient(0deg, rgba(255,255,255,1) 50%, $color-orange 50%);
  }
  .app-vendas-background {
    @include min-lg {
      background-color: $color-bright;
      padding: 4rem;
    }
  }
  .app-vendas-title {
      padding: 4rem 0 1rem 0;
    @include min-lg {
      padding: 0 0 2rem 2rem;
    }
  }
  .app-vendas-box {
    padding: 1rem 0;
    @include min-lg {
      padding: 2rem;
    }
    h3 {
      font-weight: 700;
      font-size: 1.25rem;
      margin: 1rem 0;
    }
  }
  .btn-app-vendas {
    text-align: center;
  }
}

.circle-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .circle-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $secondary;
    margin: 0 6px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    &:hover,
    &.active {
      background: $accent;
    }
  }
}



.tabs-functions input[type="radio"] {
  display: none;
}

.circle-button {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $secondary;
  cursor: pointer;
  margin-right: 10px;
}

.circle-button.active {
  background-color: $accent; /* Cor do botão ativo */
}







