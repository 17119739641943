$subsTop: 4px;
.is-sticky {
	.mega-menu__subs {
		.box {
			top: $subsTop + 1;
		}
	}
	.mega-menu__detail {
		bottom: 0;
		height: 70%;
	}
}

.black-layer {
	transition: ease all 0.2s;
	opacity: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	z-index: 5;
	top: 0;
	left: 0;
	visibility: hidden;
}

.mega-menu {
	display: flex;
	height: 100%;
	position: relative;
	z-index: 1;
	font-family: $font-2;
	.box-menu {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 48px 0;
	}
	&__item {
		position: relative;
		&--segmentos {
			.mega-menu__detail {
        bottom: 0;
		    height: 95%;
				width: 175px;
			}
		}
		&--funcionalidades {
			.mega-menu__detail {
        bottom: 0;
		    height: 95%;
				width: 214px;
			}
		}
		&--integracoes {
			.mega-menu__detail {
        bottom: 0;
        height: 95%;
				width: 172px;
			}
		}
		&--produtos {
			.mega-menu__detail {
        bottom: 0;
		    height: 95%;
				width: 157px;
			}
		}
	}
	&__text {
    font-size: .95rem;
		position: relative;
		z-index: 99;
	}
	&__detail {
		display: none;
		background: #fff;
		height: 112%;
		left: -20px;
		position: absolute;
		top: 58%;

		&::before {
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 20px;
			background: $color-orange;
			border-radius: 0 0 16px 0;
		}
		&::after {
			content: "";
			height: 100%;
			right: 0;
			position: absolute;
			top: 0;
			width: 20px;
			background: $color-orange;
			border-radius: 0 0 0 16px;
		}
	}
	&__link {
		position: relative;
		font-size: .9rem;
		display: flex;
		height: 48px;
		align-items: center;
		padding: 0 16px;
		//margin: 0 2px;
		color: #fff;
		font-family: $font-2;
		cursor: pointer;
		border-radius: 50px;
		@extend %transition;
		&:hover {
			color: $color-1;
			background-color: #fff;
		}
		&--withArrow {
			position: relative;
			padding-right: 32px;
			&::after {
				content: "";
				display: inline-block;
				width: 8px;
				height: 8px;
				border-top: 2px solid #fff;
				border-right: 2px solid #fff;
				transform: rotate(-225deg) skew(7deg, 7deg);
				right: 16px;
				top: 50%;
				margin-top: -6px;
				position: absolute;
			}
			&.is-active {
				background-color: #fff;
				color: $color-1;

				&::after {
					content: "";
					border-color: $color-1;
				}
				&::before {
					background: #fff;
					content: "";
					height: 115%;
					left: 0;
					position: absolute;
					top: 50%;
					width: 100%;
					z-index: 0;
				}
			}
		}
	}
	.mega-menu__link--withArrow {
		&:hover {
			&::after {
				content: "";
				border-color: $color-1;
			}
		}
	}
	&__subs {
		align-items: center;
		justify-content: center;
		position: fixed;
		left: 50%;
		margin-left: calc(-1140px / 2);
		height: auto;
		z-index: 109;
		display: none;
		> ul > li > a {
			display: inline-block;
			font-family: inherit;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.25;
			padding: 0.75rem 0;
			border: none;
			outline: none;
			text-transform: capitalize;
			transition: all 0.3s ease;
		}
		.box {
			position: relative;
			top: $subsTop + 12;
			width: 1140px;
			height: auto;
			background-color: #fff;
			z-index: 1;
			padding: 3rem 2rem;
			//box-shadow: 0px 12px 15px 2px rgba(0, 0, 0, 0.4);
			border-radius: 1rem;
		}
		.menu-title {
			margin-bottom: 0;
			text-align: center;
			font-size: 18px;
			color: #4b4b4b;
		}
		&--segmentos {
			.menu-title {
				margin-bottom: 20px;
				text-align: center;
				font-size: 2rem;
				font-weight: 900;
				color: #7d7d7d;
			}
			.box-menu-item {
				position: relative;
				width: 45%;
				margin-left: 50px;
				// &:last-child {
				// 	&::after {
				// 		display: none;
				// 	}
				// }
				&::after {
					position: absolute;
					content: "";
					background: #dcdcdc;
					right: 2%;
					top: 15%;
					height: 300px;
					width: 1px;
				}
			}

			.box-line::after {
				display: none;
			}

			.box-menu-item--title {
				display: flex;
				align-items: center;
				p {
					font-size: 1rem;
					line-height: 1.2;
					span {
						font-size: 2rem;
						font-weight: 900;
					}
				}
			}

      .box-menu-item--img {
        width: 7rem;
        margin-right: 1rem;
      }

			.box-menu-list {
				margin-top: 2rem;
			}

			.box-menu-list li {
				margin-top: 20px;
				a {
          span {
            margin-left: 40px;
            font-size: .9rem;
            font-weight: 500;
            &:hover {
              color: $orange;
              transition: 0.2s ease;
            }
          }
				}
			}

			li.list-img {
				background-repeat: no-repeat;
				background-size: contain;
			}

			li.cosmeticos {
				background-image: url("#{$imagePath}/mega-menu/segmentos/cosmeticos.svg");
			}

			li.alimentos {
				background-image: url("#{$imagePath}/mega-menu/segmentos/alimentos.svg");
			}

			li.bebidas {
				background-image: url("#{$imagePath}/mega-menu/segmentos/bebidas.svg");
			}

			li.hospitalar {
				background-image: url("#{$imagePath}/mega-menu/segmentos/produtos-hospitalares.svg");
			}

			li.quimica {
				background-image: url("#{$imagePath}/mega-menu/segmentos/quimica.svg");
			}
		}

		&--funcionalidades {
			.box-menu-item {
				position: relative;
				display: flex;
				align-items: center;
				width: 32.2%;
				&::after {
					position: absolute;
					content: "";
					background: #dcdcdc;
					right: 28px;
					height: 70px;
					width: 1px;
				}
				img {
					width: 20%;
				}
			}

			.box-line::after {
				display: none;
			}

			.box-menu-item--texto {
				margin-left: 10px;
				width: 55%;
				.funcionalidades-title {
					position: relative;
					font-weight: 800;
					font-size: 1rem;
					margin-bottom: 1rem;
					&::before {
						position: absolute;
						content: "";
						background: $color-orange;
						bottom: -8px;
						height: 2px;
						width: 30px;
					}
				}
				p {
          color: $color-dark;
					font-weight: 400;
					font-size: .78rem;
					&:hover {
						color: $color-orange;
					}
				}
			}
		}

		&--integracoes {
			.menu-button {
				text-align: center;
				margin-top: 40px;
				a {
					font-size: 15px;
					text-decoration: underline;
					color: $color-orange;
					&:hover {
						color: #1eb55c;
					}
				}
			}

			.box-menu-item {
				position: relative;
				display: flex;
				align-items: center;
				width: 32.2%; 
				&::after {
					position: absolute;
					content: "";
					background: #dcdcdc;
					right: 28px;
					height: 70px;
					width: 1px;
				}
				img {
					width: 24%;
				}
			}

			.box-line::after {
				display: none;
			}

			.box-menu-item--texto {
				margin-left: 10px;
				width: 55%;
				h4 {
					position: relative;
					font-weight: 900;
					font-size: 16px;
					margin-bottom: 15px;
					&::before {
						position: absolute;
						content: "";
						background: $color-orange;
						bottom: -8px;
						height: 2px;
						width: 30px;
					}
				}
				p {
          color: $color-dark;
					font-weight: 300;
					font-size: 14px;
					&:hover {
						color: $color-orange;
					}
				}
			}
		}
		&--produtos {
			.menu-button {
				text-align: center;
				margin-top: 40px;
				a {
					font-size: 15px;
					text-decoration: underline;
					color: $color-orange;
					&:hover {
						color: #1eb55c;
					}
				}
			}
         
         //testar largura do box aqui
			.box-menu-item {
				position: relative;
				display: flex;
				flex-direction: column;
				width: 20%;
				min-height: 228px;
				&::after {
					position: absolute;
					content: "";
					background: #dcdcdc;
					right: 28px;
					//height: 70px;
					width: 1px;
				}
				img {
					width: 40%;
				}
			}

			.box-line::after {
				display: none;
			}

			.box-menu-item--texto {
				margin-left: 10px;
				width: 70%;
				.produtos-title {
          color: $color-dark;
					position: relative;
					font-weight: 800;
					font-size: 1rem;
					margin-bottom: 1rem;
					&::before {
						position: absolute;
						content: "";
						background: $color-orange;
						bottom: -8px;
						height: 2px;
						width: 30px;
					}
				}
				p {
          color: $color-dark;
					font-weight: 400;
					font-size: .78rem;
					&:hover {
						color: $color-orange;
					}
				}
			}
		}
	}
  .btn__menu {
    display: inline-block;
    margin-top: 2rem;
    color: #fff;
    font-weight: 500;
    font-size: .95rem;
    transition: 0.2s ease;
    background: $color-orange;
    border-radius: 5px;
    padding: .5rem 1rem;
    &:hover {
      background: $color-orange-darker;
    }
  }
}