// Core variables and mixins
@import "lg-variables";
@import "node_modules/lightgallery/src/sass/lg-mixins";
@import "node_modules/lightgallery/src/sass/lg-fonts";
@import "node_modules/lightgallery/src/sass/lg-theme-default";
// @import "node_modules/lightgallery/src/sass/lg-thumbnail";
@import "node_modules/lightgallery/src/sass/lg-video";
// @import "node_modules/lightgallery/src/sass/lg-autoplay";
// @import "node_modules/lightgallery/src/sass/lg-zoom";
// @import "node_modules/lightgallery/src/sass/lg-pager";
// @import "node_modules/lightgallery/src/sass/lg-fullscreen";
// @import "node_modules/lightgallery/src/sass/lg-share";
// Core
@import "node_modules/lightgallery/src/sass/lg-core";
.lg-toolbar .lg-close:after {
  content: "Fechar";
  font-size: 1.4rem;
}
.lg-toolbar .lg-icon {
  width: 100%;
  font-family: $font-2 !important;
}
.lg-fullscreen {
  display: none;
}