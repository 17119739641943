/* novo sistema de titles - trocar gradualmente por este */
.title {
  font-weight: 900;
  position: relative;
  &-xxl {font-size: min(calc(1.4rem + 1vw), 3rem);}
  &-xl {font-size: min(calc(1.4rem + 1vw), 2.75rem);}
  &-lg {font-size: min(calc(1.4rem + 1vw), 2rem);}
  &-md {font-size: min(calc(1.425rem + 2.1vw), 1.5rem);}
  &-sm {font-size: min(calc(1.425rem + 2.1vw), 0.75rem);}

  &-underline {
    margin-bottom: 2.7rem;
    &::before {
      border-bottom: 5px solid $orange;
      bottom: -24px;
      content: "";
      display: inline-block;
      left: 0;
      position: absolute;
      width: 70px;
    }
  }
}

.title {
  &-1 {
    font-weight: 900;
    position: relative;
    padding: 0;
    margin: 0 0 30px;
    font-size: 35px;
    font-family: $font-2;
    line-height: 1.1;
    &::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      height: 1px;
      border-bottom-style: solid;
      border-bottom-color: $color-1;
      border-bottom-width: 2px;
      width: 100px;
    }
  }
  &-2 {
    font-weight: 900;
    position: relative;
    padding: 0;
    font-size: 2rem;
    font-family: $font-2;
    line-height: 1.1;
    @include min-lg {
      font-size: 2.4rem;
    }
  }
  &-3 {
    position: relative;
    padding: 0;
    margin: 0 0 60px;
    font-size: 1.5rem;
    font-family: $font-2;
    line-height: 1.2;
    &__bold {
      display: block;
      clear: both;
      font-size: 2.4rem;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 0;
      display: inline-block;
      border-bottom-style: solid;
      border-bottom-color: $color-orange;
      border-bottom-width: 5px;
      width: 100px;
    }
  }
}

.subtitle {
    &-1 {
        font-size: 35px;
        font-weight: 200;
        margin-bottom: 20px;
        line-height: 1.2;
    }       
}