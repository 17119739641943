.side-nav {
  background: #212529;
  overflow: hidden;
  box-shadow: 5px 0px 20px 0px rgba(0,0,0,0.75);
  &-logo {
    margin: 20px 20px;
    float: left;
    padding-right: 70px;
    width: 90%;
  }
  &__box {
    padding: 20px;
    .btn {
      &-agendar {
        padding: 0 15px;
        width: 100%;
        &:hover {
          width: 100%;
        }
      }
      &-fone {
        display: block;
        margin-top: 40px;
        font-size: 24px;
      }
    }
  }
}

.menu-mobile {
  width: 100%;
  clear: both;
  .accordion-btn-wrap {
    width: 100%!important;
    text-align: right!important;
    font-size: 18px; 
    padding-right: 20px;
  }
  &__sub {
    line-height: 50px;
    padding-left: 20px;
  }
  ul {
    border-bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        display: block;
        line-height: normal;
        padding: 20px 20px;
        color: #fff;
        font-family: $font-2;
        font-size: 15px;
        font-weight: 300;
        &:hover {
          text-decoration: none;
        }
      }
    }
    ul {
      background: #343a40; 
      li {
        border-color: #ddd;
        a {
          padding-left: 20px;
        }
        ul {
          li {
            border-color: #666;
            a {
              background: #444;
              color: #ccc;
              padding-left: 20px;
            }
          }
        }
      }
    }
  }
}

ul {
  li {
    &.has-subnav {
      .accordion-btn {
        color: #fff;
        background: transparent;
        font-size: 21px;
        line-height: 50px;
        display: block;
      }
    }
  }
}