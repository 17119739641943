.list-benefits {
  @include min-lg {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  li {
    position: relative;
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 1.5rem 1rem 1.5rem 2.75rem;
    box-shadow: 12px 12px 30px -15px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    border: solid 1px #edeff2;
    margin-bottom: 1rem;
    @include min-lg {
      width: 32.2%;
      margin: .4rem;
    }
    &::before {
      font-family: $font-icons;
      content: '\e5ca';
      color: $orange;
      left: 1rem;
      font-size: 1.4rem;
      position: absolute;
    }
  }
}