.header-cta {
  background: $color-dark;
  font-weight: 500;
  font-size: .75rem;
  @include min-lg {
    font-size: 1rem;
    padding: 1rem 0;
  }
  padding: .5rem 1rem;
  text-align: center;
  //text-transform: uppercase;
  position: relative;
  z-index: 10;
  p {
    color: #fff;
    display: inline;
    margin-right: 4px;
    @include min-lg {
      margin-bottom: 0;
    }
  }
  a {
    @include min-lg {
      margin-top: 0;
      width: auto;
    }
    margin-top: .2rem;
    display: inline-block;
    width: 75%;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    transition: $transition;
    //background: linear-gradient(312deg, rgba(107,36,249,1) 0%, rgba(231,112,2,1) 100%);
    background: #1eb55c;
    //border: 1px solid #DEB454;
    padding: 4px 20px;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(255, 130, 0, 1);
    //animation: pulse 2s infinite;    
    &:hover {
      background: #1c944e;
    }
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  
}

/*
@include max-sm {
  .header-cta {
    display: none;
  }
}
*/