/*
%bg-degrade {
	background: linear-gradient(to right, #ff6a00 0%,#e48612 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6a00', endColorstr='#e48612',GradientType=1 );
}

%menu-hover-effect {
	&::before {
        content: '';
        height: 1px;
        width: 0px;
        position: absolute;
        background: #ffffff;
        visibility: hidden;
        transition: all 0.25s ease-in-out;
        left: 16px;
        bottom: 10px;
    }

    &:hover {
        &::before {
            visibility: visible;
            width: 30px;
        }
    }
}
*/

%transition {
  transition: ease all 0.3s;
}

$spacer: 1rem;
$spacers:(
  6: ($spacer * 4),
  7: ($spacer * 5.5),
  8: ($spacer * 6),
  9: ($spacer * 6.5),
  10:($spacer * 7),
  11:($spacer * 7.5),
  12:($spacer * 8),
  13:($spacer * 8.75),
  14:($spacer * 9.5),
  15:($spacer * 10)
);

.icon {
  &-32{width: 32px;}
  &-48{width: 48px;}
  &-64{width: 64px;}
  &-96{width: 96px;}
  &-128{width:128px;}
}
.image-center {
  margin: 0 auto;
}
.top {
  &-0{top:0;} 
  &-50{top:50%;}
  &-50{top:100%;}
}
.start {
  &-0{left:0;} 
  &-50{left:50%;}
  &-50{left:100%;}
}
.bottom {
  &-0{bottom:0;} 
  &-50{bottom:50%;}
  &-50{bottom:100%;}
}
.end {
  &-0{right:0;} 
  &-50{right:50%;}
  &-50{right:100%;}
}
.max-width {
  &-50 {
    @include min-md {
      max-width: 50%;
    }
  }
}
.h-md-100 {
  @include min-md {
    height: 100% !important;
  }
}
.preview {
  &-subs {
    position: relative;
    &::before {
    bottom: -2rem;
    content: "Clique para ampliar";
    font-style: italic;
    font-size: .75rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    }
  }
}
.sticky {
  @include min-lg {
    position: sticky!important;
    top: 7rem;
  }
}