ul.lista {
  list-style: disc;
  list-style-position: outside;
  padding-left: 15px;
  margin-bottom: 20px;
  li {
    margin-bottom: 10px
  }
}
ul.list {
  position: relative;
  li {
    margin-bottom: 1rem;
    padding-left: 1.75rem;
    &:before {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJhIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjkiIGZpbGw9IiNmMjY3M2MiIHN0cm9rZS13aWR0aD0iMCIvPjxwYXRoIGQ9Im0xMy41LDcuMTNsLTUuMSw1LjFjLS4zNy4zNy0uOTguMzctMS4zNSwwbC0yLjU1LTIuNTVjLS4zNy0uMzctLjM3LS45OCwwLTEuMzVzLjk4LS4zNywxLjM1LDBsMS44NywxLjg3LDQuNDItNC40M2MuMzctLjM3Ljk4LS4zNywxLjM1LDBzLjM3Ljk4LDAsMS4zNWgwWiIgZmlsbD0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIwIi8+PC9zdmc+");
      background-repeat: no-repeat;
      content: "";
      height: 15px;
      width: 15px;
      left: 0;
      margin-top: 5px;
      position: absolute;
    }
  }
}
.list-trabalhe {
  li {
    margin-top: 2rem;
    &:first-child {
      margin-top: 0;
    }
    h3 {
      font-weight: 800;
      color: $color-orange;
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
      font-weight: 400;
      color: $color-dark;
    }
  }
}