#form-cta-solicitar-acesso-258b338684c38c23ae71 #rd-column-juvacwbp > div, #form-cta-solicitar-acesso-258b338684c38c23ae71 #rd-section-joq3m2m5c  {
    background-color: transparent !important;
    padding: 0;
}
#rdstation-bricks-embeddable-form-bricks-component-VP_LAw_NYEx26Dyd-om11A .bricks-form,
#cta-solicitar-acesso-258b338684c38c23ae71 #form-cta-solicitar-acesso-258b338684c38c23ae71 #conversion-form-cta-solicitar-acesso-258b338684c38c23ae71 section {
    padding: 0 !important
}
#form-cta-solicitar-acesso-258b338684c38c23ae71 #conversion-form-cta-solicitar-acesso .bricks-form__input {
    border-radius: 20px !important;
    padding-left: 15px !important;
    height: 40px !important;
    width: 100%;
}
/*#form-cta-solicitar-acesso-258b338684c38c23ae71 #conversion-form-cta-solicitar-acesso .bricks-form__label,
#rd-form-joq3m2m5i .with-select-flags > .phone-input-group > .phone-country {
    display: none!important;
}*/
#form-cta-solicitar-acesso-258b338684c38c23ae71 #conversion-form-cta-solicitar-acesso .bricks--component-button {
    border-radius: 100px !important;
    margin: 0;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
}

/* formulario whatsapp

#rd-form-klgj1azk .with-select-flags > .phone-input-group > .phone-country {
  display: none;
}

#rd-form-klgj1azk .with-select-flags > .phone-input-group .phone {
  width: 100%;
} */