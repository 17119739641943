#faq-content {
  padding: 4rem 1rem;
  font-size: 1rem;
  @include min-lg {
    padding: 5rem 1rem;
  }
  .container-faq {
    max-width: 1000px;
    margin: 0 auto;
  }
  a {
    color: $color-orange;
    text-decoration: underline;
    &:hover {
      color: $color-2;
    }
  }
  h2 {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: 700;
    margin: 2rem 0;
  }
  .answers ul li {
    margin-bottom: 20px;
    padding-left: 30px;
    &::before {
     content: '';
     position: absolute;
     //background: url(#{$imagePath}/icones/check-solid.svg);
     background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"%3E%3Cpath d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"%3E%3C/path%3E%3C/svg%3E');
     background-repeat: no-repeat;
     width: 15px;
     height: 15px;
     left: 40px;
     margin-top: 5px;
    }
  }
  .question {
    font-weight: 500;
    position: relative;
    background: $color-bright;
    margin: 0;
    padding: 30px 30px 30px 58px;
    margin-top: 10px;
    display: block;
    width:100%;
    cursor: pointer;
    border-radius: 10px;
    transition: ease .2s;
    box-shadow: 0px 2px 0px 0px #f0f0f0;
    &:hover{
        background-color: $color-orange;
        color: #fff;
        &::before{
        filter:brightness(4);
        }
    }
  }
  .questions:checked ~ .question{
    border-radius: 10px 10px 0 0;
    background-color: $color-orange;
    color: #fff;
  }
  .answers {
    background: $color-bright;
    padding: 0px 15px;
    border-radius: 0 0 10px 10px;
    margin: 0;
    height: 0;
    overflow: hidden;
    z-index: 0;
    position: relative;
    opacity: 0;
    transition: .2s ease;
    box-shadow: 0px 2px 0px 0px #f0f0f0;
    p {
      margin-bottom: 20px;
    }
  }
  .questions:checked ~ .answers{
    height: auto;
    opacity: 1;
    padding: 28px 40px;
  }
  .question::before {
    content: '';
    background: url(#{$imagePath}/icones/arrow.svg);
    background-repeat: no-repeat;
    position: absolute;
    z-index: 5;
    left: 32px;
    top: 34px;
    width: 14px;
    height: 14px;
    transition: ease .2s;
  }
  .questions:checked ~ .question::before{
    transform: rotate(90deg);
    transform-origin: 20% 58%;
    filter: brightness(4);
  }
  .questions {
    display: none;
  }
}



















