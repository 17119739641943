.parceiros {
  &__numeros {
    background: $light;
    border-radius: 2rem;
    padding: 1rem;
    @include min-lg {
      padding: 2rem;
    }
  }
  &__lista {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
      @include min-lg {
        margin-top: 0;
      }
    li {
      width: 48.5%;
      border-radius: .5rem;
      @include min-lg {
        width: 30.8%;
        margin: .5rem;
      }
      @include max-sm {
        &:nth-child(even) {
          margin-left: .25rem;
        }
        &:nth-child(odd) {
          margin-right: .25rem;
        }
      }
      padding: 1rem;
      font-size: .85rem;
      font-weight: 500;
      &:hover {
        background: $orange;
        color: #fff;
        span {
          color: #fff;
        }
      }
      span {
        color: $orange;
        font-size: 1.44rem;
        font-weight: 800;
        display: inline-block;
        margin-bottom: .2rem;
      }
    }
  }
  &__beneficios {
    li {
      margin-bottom: 3rem;
      border-left: 2px solid $orange;
      padding-left: 1rem;
      h3 {
        font-size: 1.25rem;
        font-weight: 800;
        margin-bottom: 1rem;
      }
    }
  }
  &__segmentos {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      img {
        max-width: 50px;
      }
      p {
        color: #fff;
        padding-left: 1rem;
        margin-bottom: 0;
      }
    }
  }
  &__tabela {
    background: linear-gradient(0deg, rgba(255,255,255,0) 15%, rgba(242,103,60,1) 15%);
    @include min-lg {
      background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(211,61,14,1) 50%, rgba(242,103,60,1) 80%);
    }
  }
}

.parceiros-image {
  &-mosaic {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    grid-auto-rows: 1fr;
    @include min-md {
      padding: .5rem;
    }
    &-item {
      position: relative;
      padding-top: 100%;
    }
  }
}

.parceiros-image-mosaic-item > *{
  border-radius: .5rem;
  width: 100%;
  height: 100%;
  top:0;
  position: absolute;
  object-fit: cover;
  transition: $transition;
  &:hover {
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.1);
    filter: brightness(75%);
  }
}

@include min-lg {
  .parceiros-image {
    &-mosaic {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .parceiros-image-mosaic *:nth-child(3) {
    grid-column: span 2;
    grid-row: 2;
    padding-top: 50%;
  }
}