.cta_header {
  background: $black;
  //background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(242,103,60,1) 69%);
  min-height: 350px;
  background-image: url("#{$imagePath}/layout/campanha/campanha-fevereiro-mobile.webp");
	background-repeat: no-repeat;
	background-size: contain;
  background-position: center;
  @include min-md {
    min-height: 145px;
    background-image: url("#{$imagePath}/layout/campanha/campanha-fevereiro.webp");
  }
  /*&-title {
    text-align: center;
    p {
      font-size: 1.2rem;
      @include max-sm {
        font-size: 1rem;
      }
      @include max-sm {
        span {
          font-size: 1.23rem;
        }
      }
    }
  }*/
  /*&-icon {
    margin-left: 15px;
    &::before {
     content: '';
     position: absolute;
     //background: url(#{$imagePath}/icones/check-solid.svg);
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Ccircle cx='256' cy='256' r='256' fill='%2350c878'/%3E%3Cpath d='M369 209l-128 128c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47 111-111.1c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l.1.1Z' fill='%23fff'/%3E%3C/svg%3E");
     background-repeat: no-repeat;
     width: 20px;
     height: 20px;
     margin-left: -30px;
    }
  }*/
  /*&-countdown {
    color: #fff;
    font-weight: 700;
  }*/
  /*.cta_header-button {
    margin: .75rem 0;
    a {
      text-align: center;
      background: $green;
      border-radius: 5px;
      padding: .5rem 1rem;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      display: block;
      animation: blink 0.8s infinite alternate;
      @keyframes blink {
        100% {
          box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #7dc582,
            0 0 30px #7dc582, 0 0 50px #7dc582;
        }
      }
      &:hover {
        background-color: #7dc582;
        animation: none;
      }
    }
  }*/
}

/*
#countdown {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  span {
    font-size: 1rem;
    font-weight: bold;
    background-color: $orange;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 5px;
    @include min-lg {
      font-size: 2rem;
    }
  }
  p {
    font-size: .9rem;
    font-weight: 700;
    color: $white;
    margin: 0;
  }
}

.time-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  */

/* utilizado na black november
.countdown {
  display: flex;
  justify-content: center;
  gap: 15px;
  span {
    font-weight: bold;
    background-color: $orange;
    color: white;
    padding: 10px 20px;
    @include min-md {
      padding: 20px 30px;
    }
    border-radius: 5px;
    margin-bottom: 5px;
  }
  p {
    font-size: .9rem;
    font-weight: 500;
    color: $white;
    margin: 0;
  }
}
.time-box {
  margin-top: .7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.time-box-header {
  font-size: 1.28rem;
}
.time-box-lp {
  font-size: 1.7rem;
  @include min-lg {
    font-size: 3rem;
    padding: 10px 28px;
  }
} */



