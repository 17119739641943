.headerCatalogo {
  text-align: center;
  margin-bottom: 2rem;
  img {
    margin: 0 auto;
    width: 50%;
    @include min-lg {
      width: 15%;
    }
  }
}

.footerCatalogo {
  background: $color-dark;
  padding: 4rem 0;
  text-align: center;
  img {
    margin: 0 auto;
    width: 50%;
    @include min-lg {
      width: 15%;
    }
  }
  p {
    color: #fff;
  }
}

input[type="radio"]:checked + .label__content {
  border-color: $orange;
  background-color: $orange;
  color: $white;
}

.tabs {
	display: flex;
	flex-wrap: wrap;
  justify-content: center;
  margin-top: -7rem;
  z-index: 5;
  position: relative;
  &__background {
    background: #f8f9fa;
    padding-bottom: 5rem;
  }
  &__content {
    margin-top: 0;
    justify-content: left;
  }
  &__form {
    background: $color-dark;
    border-radius: 5px;
    padding: 1rem;
    margin-top: 4rem;
    @include min-lg {
      padding: 4rem;
    }
    h2 {
      color: #fff;
      font-weight: 800;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    p {
      color: #fff;
    }
  }
  label {
    text-align: center;
    order: 1; // Put the labels first
    cursor: pointer;
    display: block;
    transition: background ease 0.2s;
    border: 1px solid #d6dce1;
    border-radius: 5px;
    background-color: $white;
    &:active,
    &:focus {
      border-color: $orange;
    }
  }
  .label {
    &__tab {
      @include min-lg {
        width: 18%;
        padding: 2rem;
        margin: .5rem;
      }
      margin: .5rem 0;
      padding: 1rem;
      font-weight: bold;
      transition: $transition;
      &:hover {
        border-color: #cad2d9;
        background-color: #cad2d9;
        color: $white;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        transform: scale(.97);
      }
      img {
        margin: 0 auto;
        width: 15%;
        @include min-lg {
          width: 50%;
        }
      }
      h3 {
        margin-top: .5rem;
      }
      &.clicked {
        border-color: $orange;
        background-color: $orange;
        color: $white;
        box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
      }
    }
    &__content {
      padding: .5rem;
      font-weight: 500;
      font-size: .758rem;
      @include min-lg {
        margin-right: .5rem;
        &:last-of-type {
          margin-right: 0;
        }
      }
      &:hover{
        border-color: #b3bec9;
        background: #b3bec9;
      }
      &.clicked {
        background: $color-orange;
        border-color: $color-orange;
        color: #fff;
      }
    }
  }
  .tab {
    order: 99; // Put the tabs last
    flex-grow: 1;
    width: 100%;
    display: none;
    @include min-lg {
      padding: 2rem 0;
    }
    &__content {
      background: #fff;
      margin-top: 1rem;
      border-radius: 5px;
      border: 1px solid #d6dce1;
      padding: 2rem;
      h4 {
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
    &:checked {
      display: block;
    }
    &__button {
      background-color: $color-orange;
      padding: .5rem 1rem;
      font-weight: 500;
      color: #fff;
      margin: 1rem 0;
      display: inline-block;
      border-radius: 5px;
      transition: $transition;
      &:hover {
        background: #d95c36;
      }
    }
    &__list-comercial {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        width: 50%;
        @include min-lg {
          width: 34%;
        }
        margin: 1rem 0;
      }
    }
    &__list-integracao {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        width: 50%;
        @include min-lg {
          width: 28%;
        }
        margin: 1rem 0;
      }
    }
  }
  input[type="radio"] {
    display: none;
    /*&:checked + label {
      background: #fff;
    }*/
    &:checked + label + .tab{
      display: block;
    }
  }
}

@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}