/* novo sistema de botões - trocar gradualmente por este */
.button {
  border-radius: .5rem;
  text-align: center;
  display: block;
  padding: .5rem 1rem;
  font-weight: 500;
  color:$white;
  @include min-lg {
    display: inline-block;
  }
  &-primary {
    background: $orange;
    &-outline {
      background: transparent;
      border: solid 1px $orange;
      color: $orange;
      &:hover {
        background: $orange;
      }
    }
  }
  &-secondary {
    background: $black;
    &-outline {
      background: transparent;
      border: solid 1px $white;
      &:hover {
        background: $white;
      }
    }
  }
  &-outline {
    background: transparent;
    border: solid 1px $white;
    &:hover {
      background: $white;
    }
  }
  &-lg {
    padding: 1rem 2rem;
    font-size: min(calc(.5rem + 2.1vw), 1.25rem);
    font-weight: 700;
  }
  &-link {
    color: $orange;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      color: white;
    }
  }
}

//botão dos slides de segmentos
.button {
  &__segments {
    color: #fff;
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    padding-right: 1.5rem ;
    text-decoration: underline;
    display: inline-block;
    transition: $transition;
    &:hover {
      color: $orange;
      text-decoration: underline;
      span {
        svg {
          transition: $transition;
          fill: $orange;
          margin-left: 10px;
        }
      }
    }
    span {
      margin-left: 5px;
    }
  }
}

/* antigo sistema de botões */
.btn {
    &-menu-mobile {
        position: relative;
        float: right;
        display: block;
        border-radius: 30px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__icon {
            height: 24px;
            width: 24px;
            fill: #fff;
            margin: 0 auto;
        }
        @include min-lg {
            display: none;
        }
    }
    &-1 {
        padding: 10px 30px;
        border-radius: 50px;
        transition: ease all 0.3s;
        background: $color-2;
        color: #FFF;
        font-weight: 500;
        &:hover {
            color: #fff;
        }
        a {
            color: #fff
        }
    }
    &-clean {
      display: inline-block;
      padding: 10px 30px;
      border-radius: 50px;
      transition: ease all 0.3s;
      background: #fff;
      font-weight: 500;
      box-shadow: 0 6px 12px #ccc;
      min-width: 300px;
      text-align: center;
      font-size: 1rem;
      margin-top: 20px;
      @include min-xl {
        margin-top: 0;
      } 
      &:hover {
        background: $color-2;
        color: #FFF;
      }
      &--2 {
        color: $color-dark;
        font-weight: 700;
        font-size: .95rem;
        text-transform: uppercase;
        border-radius: 5px;
        background-color: $color-orange;
        box-shadow: none;
        @include max-sm {
          width: 100%;
        }
        &:hover {
          background: #ec5700;
          color: $color-dark;
        }
      }
    }
    // botão telefone do header
    &-fone {
      position: relative;
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      margin-top: 15px;
      margin-left: 1rem;
      &::after {
        content: 'Para vendas';
        text-transform: uppercase;
        font-weight: 500;
        font-size: .7rem;
        position: absolute;
        top: -15px;
        left: 0;
      }
      &::before {
        content: "";
        position: absolute;
        border-bottom: 2px solid #fff;
        bottom: -5px;
        width: 0;
        left: 0;
        transition: width .3s ease-in-out;
      }
      &:hover {
        color: #fff;
      &::before {
        width: 100%;
      }
    }
  }

  //botão cta para formulários
  &-cta {
    background-color: $color-orange;
    transition: all .3s ease;
    display: inline-block;
    margin: 0 auto;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: $color-dark;
    font-weight: 600;
    border-radius: 5px;
    border: 2px solid $color-dark;
    padding: 10px 30px;
    &:hover {
      background-color: #fff;
      border-color: #fff;
    }
  }
}

.btns-funcionalidades {
    @include max-sm {
      text-align: center;
  }
}

.btns-funcionalidades-cta {
  font-family: $font-3;
  background-color: $color-2;
  transition: all .3s ease;
  display: block;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  padding: 10px 20px;
  @include min-lg {
    font-size: 1.1rem;
    margin-top: 1rem;
    max-width: 400px;
  }
  &:hover {
    background-color: $color-success;
    color: white;
  }
}

.btns-funcionalidades-voltar {
  position: relative;
  font-family: $font-3;
  font-size: .9rem;
  font-weight: 600;
  text-decoration: underline;
  transition: all .3s ease;
  color: #787878;
  margin-top: 1.2rem;
  display: inline-block;
  margin-left: -1rem;
  @include min-lg {
    margin-left: 1rem;
  }
  &::after {
    position: absolute;
    content: '';
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='9.9558' viewBox='0 0 10 9.9558' fill='#787878'><path fill-rule='evenodd' d='M9.7713,4.3905a.8689.8689,0,0,1,0,1.1749L5.931,9.7105A.7305.7305,0,0,1,4.9,9.7711a.7072.7072,0,0,1-.0606-.0606.8811.8811,0,0,1,0-1.1749L8.1421,4.9779,4.8449,1.42a.8689.8689,0,0,1,0-1.1749A.7236.7236,0,0,1,5.8663.1807.6932.6932,0,0,1,5.931.2454L9.7769,4.3905ZM1.3148.2454,5.1607,4.3905a.8811.8811,0,0,1,0,1.1749L1.3148,9.7105a.7237.7237,0,0,1-1.0214.0647.7055.7055,0,0,1-.0647-.0647.8689.8689,0,0,1,0-1.1749L3.5259,4.9779.2287,1.42a.8811.8811,0,0,1,0-1.1749A.7236.7236,0,0,1,1.25.1807a.6932.6932,0,0,1,.0647.0647Z'/></svg>");
    background-repeat: no-repeat;
    right: -20px;
    top: 30%;
    width: 10px;
    height: 10px;
    transition: all .3s ease;
  }
  &:hover {
    color: $color-orange;
    text-decoration: underline;
    &::after {
      right: -25px;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='9.9558' viewBox='0 0 10 9.9558' fill='#ff5f00'><path fill-rule='evenodd' d='M9.7713,4.3905a.8689.8689,0,0,1,0,1.1749L5.931,9.7105A.7305.7305,0,0,1,4.9,9.7711a.7072.7072,0,0,1-.0606-.0606.8811.8811,0,0,1,0-1.1749L8.1421,4.9779,4.8449,1.42a.8689.8689,0,0,1,0-1.1749A.7236.7236,0,0,1,5.8663.1807.6932.6932,0,0,1,5.931.2454L9.7769,4.3905ZM1.3148.2454,5.1607,4.3905a.8811.8811,0,0,1,0,1.1749L1.3148,9.7105a.7237.7237,0,0,1-1.0214.0647.7055.7055,0,0,1-.0647-.0647.8689.8689,0,0,1,0-1.1749L3.5259,4.9779.2287,1.42a.8811.8811,0,0,1,0-1.1749A.7236.7236,0,0,1,1.25.1807a.6932.6932,0,0,1,.0647.0647Z'/></svg>");
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(0)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
}

.ButtonScroll {
  position: absolute;
  background: #fff;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  bottom: -30px;
  right: 50%;
  margin-right: -31px;
  &-container {
    position: relative;
  }
  &-icon {
    width: 22px;
    height: 22px;
    fill: $color-1-dark;
  }
}

.header__actions {
  .header__buttons {
    @include min-xl {
      display: inline-flex!important;
    }
  }
}