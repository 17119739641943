.th {
  text-align: center;
  box-shadow: 0px 0px 40px 15px rgba(242,103,60,0.5);
  border-radius: 10px;
  position: relative;
  transition: all 0.3s ease;
  padding: 1rem 0;
  @include min-lg {
    padding: 0;
  }
  &-title {
    font-size: 1rem;
    padding-bottom: 2rem;
    @include min-lg {
      font-size: 1.5rem;
      padding-bottom: 0;
    }
  }
  &-price {
    &-title {
      font-weight: 800;
      @include min-lg {
        font-size: 1.2rem;
      }
    }
    &-old {
      font-size: 12px;
      font-weight: 600;
      @include min-lg {
        font-size: 1rem;
      }
      span {
        //text-decoration: line-through;
        font-weight: 700;
        @include min-lg {
          font-size: 1.2rem;
        }
      }
    }
    &-new {
      color: $white;
      font-size: 1.4rem;
      font-weight: 800;
      
      @include min-lg {
        font-size: 2.2rem;
      }
      span {
        position: relative;
        font-size: .7rem;
        @include min-lg {
          font-size: 1rem;
        }
      }
      &-pro {
        color: $white;
        font-size: 1.4rem;
        @include min-lg {
          font-size: 3rem;
          color: $black;
        }
      }
    }  
    &-pro {
      position: relative;
      &::before {
        position: absolute;
        top: -15px;
        content: 'Melhor compra';
        background: $green;
        padding: 5px;
        font-size: .5rem;
        border-radius: 1rem;
        color: $white;
        font-weight: 500;
        text-transform: uppercase;
        width: 95px;
        transform: translate(-50%, -50%);
        @include min-lg {
          font-size: .8rem;
          padding: 5px 10px;
          top: -5px;
          width: 150px;
        }
      }
      @include min-lg {
        background: #f9c2b1;
        padding: 1rem 0;
        box-shadow: 0px 0px 0px 8px #f59476;
        border-radius: 10px;
      }
    }
  }
  &-implantacao {
    background:#1d2a35 ;
    box-shadow: none;
    border: 2px solid $orange;
    padding: 1rem 0;
    @include min-lg {
      padding: 2.4rem 0;
    }
    .th-title {
      padding-bottom: 1rem;
      @include min-lg {
        padding-bottom: 0;
      }
    }
    .th-price-new {
      color: $orange;
    }
  }
  .price-off {
    /*
    &:hover {
      &:before {
        transform: scale(1.1);
      }
    }
    */
    &:before {
      position: absolute;
      content: "";
      width: 85px;
      height: 25px;
      bottom: -40px;
      transition: transform .3s ease;
      background-repeat: no-repeat;
      transform: translate(0%, -50%);
      @include min-lg {
        width: 130px;
        height: 45px;
        bottom: -64px;
      }
    }
    // Planos
    &-plus {
      &:before {
        background-image: url(#{$imagePath}/layout/black-november/21off.svg);
      }
    }
    &-pro {
      &:before {
        background-image: url(#{$imagePath}/layout/black-november/28off.svg);
        @include min-lg {
          bottom: -48px;
        }
      }
    }
    &-ultra {
      &:before {
        background-image: url(#{$imagePath}/layout/black-november/21off.svg);
      }
    }
    // Planos implantação
    &-plus-implantacao {
      &:before {
        background-image: url(#{$imagePath}/layout/black-november/30off.svg);
      }
    }
    &-pro-implantacao {
      &:before {
        background-image: url(#{$imagePath}/layout/black-november/35off.svg);
      }
    }
    &-ultra-implantacao {
      &:before {
        background-image: url(#{$imagePath}/layout/black-november/34off.svg);
      }
    }
    &-clientes-implantacao {
      &:before {
        background-image: url(#{$imagePath}/layout/black-november/20off.svg);
      }
    }
  }
}

.tb {
  text-align: center;
  margin-top: 2rem;
  @include min-lg {
    margin-top: 5rem;
  }
  p {
    margin: 0;
    padding: 2rem 0;
    font-weight: 500;
  }
  &-implantacao {
    padding: 2rem 0;
    color: $white;
    span {
      display: block;
      font-size: .75rem;
    }
    p {
      font-size: 1.2rem;
      font-weight: 800;
      padding: 0;
      color: $orange;
    }
  }
  .row {
    
      margin-right: 0;
      margin-left: 0;
    
  }
  .red {
    color: $red;
  }
  .green {
    color: $green;
  }
  
  .small {
    font-size: .8rem;
    font-weight: 400;
    text-align: left;
    @include min-lg {
      font-size: 1rem;
      text-align: center;
      font-weight: 500;
    }
  }
  h3 {
    font-weight: 700;
    font-size: 1.1rem;
    @include max-sm {
      padding-top: 2rem;
      font-size: 1rem;
    }
  }
  &-title {
    font-size: 1.7rem;
    font-weight: 800;
    margin-top: 5rem;
    margin-bottom: 2rem;
    color: $orange;
    text-transform: uppercase;
  }
}

.fixed {
  position: sticky;
  top: 10px;
  left: 0;
  right: 0;
  z-index: 10;
  //padding: 1rem 0;
  @include min-lg {
    position: sticky;
    top: 20px;
  }
}

.fixed-implantacao {
  position: sticky;
  top: 10rem;
  z-index: 5;
}

.table-row-light {
  background-color: #4b5964;
  border-radius: 5px;
}

.btn-expandir {
  margin-top: 2rem;
  margin-bottom: 2rem;
  @include min-lg {
    margin-bottom: 5rem;
  }
  p {
    font-size: 1rem;
    font-weight: 500;
    color: $orange;
    padding: 0;
    margin-bottom: .75rem;
  }
  svg {
    width: 20px;
    height: 22px;
    fill: $orange;
    animation: bounce 2s ease-in-out forwards;
    animation-iteration-count: infinite;
  }
  &:hover {
    cursor: pointer;
  }
}

.text-line-through {
  position: relative;
}

.text-line-through::after {
  content: '';
  position: absolute;
  top: 50%; /* Posição da linha no centro do texto */
  left: 0;
  width: 100%;
  height: 2px; /* Espessura da linha */
  background-color: red; /* Cor da linha */
  transform: translateY(-50%); /* Centraliza a linha verticalmente */
}