.bold {
  font-weight: 700;
}
.link {
  color: $orange;
  &:hover {
    color: #e48612
  }
}
.icon {
  display: inline-block;
}
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__col {
    display: flex;
    align-items: center;
    padding: 0 13px;
  }
}
.hidden-mobile {
  display: none;
  @include min-lg {
    display: block
  }
}
.BannerHeader__form {
  min-height: 240px;
}
/* novos padrões */
.bg {
  &-light {
    background-color: $light;
    @include min-md {
      &-gradient{
        background: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(248,249,250,1) 50%);
      }
      &-gradient-top{
        background: linear-gradient(180deg, rgba(248,249,250,1) 50%, rgba(255,255,255,0) 50%);
      }
    }
  }
  &-lighter {
    background-color: $lighter;
  }
  &-black {
    background-color: $black;
    @include min-md {
      &-gradient {
        background: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(33,42,52,1) 50%);
      }
    }
  }
  &-orange {
    background-color: $orange;
    @include min-md {
      &-gradient{
        background: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(33,42,52,1) 50%);
      }
    }
  }
  &-white {
    background-color: $white;
    @include min-md {
      &-gradient {
        background: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(33,42,52,1) 50%);
      }
    }
  }
  &-color {
    &-gradient {
      background: linear-gradient(270deg, rgba(253,208,46,1) 0%, rgba(253,94,4,1) 100%);
    }
  }
  &-texture {
    background-image: url(#{$imagePath}/layout/indique-ganhe/noise-light.png);
  }
  &-green {
    background-color: $green;
  }
}

.bg {
  &-image {
    @include min-lg {
      background-repeat: no-repeat;
      background-image: url(../../images/layout/background-vetor-orange.svg);
      &--start {
        //background-position: center left;
        background-position: -20% center;
      }
      &--end {
        //background-position: center right;
        background-position: 120% center;
      }
    }
  }
}

.distribuidora_background-segmentos {
  background-repeat: no-repeat;
  background-image: url(../../images/layout/background-distribuidora.webp);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.box-shadow-small {
  box-shadow: $box-shadow-small;
}


.tag {
  border-radius: 50px;
  padding: 5px 10px;
  font-size: .75rem;
  &-hibrido {
    background: #e0b0ff;
  }
  &-presencial {
    background: $color-2;
  }
  &-remoto {
    background: $orange;
  }
  &-gold {
    background: #ffbf00;
  }
}