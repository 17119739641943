#clientsTestimonial {
  padding: 5rem 0 5rem 0;
  background-color: #f8f9fa;
  @include min-lg {
    padding: 7rem 0 7rem 0;
  }
}

.clients-testimonial-text {
  margin-top: 4rem;
  @include min-lg {
    padding: 0 4rem;
    margin-top: 0;
  }
  text-align: center;
  img {
    margin: 0 auto;
    border-radius: 100px;
  }
  p {
    font-style: italic;
    margin-top: 2rem;
    line-height: 1.4;
    span {
      margin-top: 1rem;
      display: block;
      font-style: normal;
      strong {
        font-style: normal;
        font-size: 1.1rem;
        font-weight: 700;
      }
    }
  }
}

.clients-box {
  position: relative;
  &_img {
    position: absolute;
    top: -20px;
    left: 0;
    img {
      border-radius: 100px;
      border: 4px solid $color-orange;
    }
  }
  &_text {
    //background-color: $color-bright;
    background-color: #fff;
    border: 2px solid #edeff2;
    box-shadow: 10px 15px 15px -14px rgba(0,0,0,0.1);
    border-radius: 75px 10px 10px 10px;
    padding: 2rem;
    padding-top: 8rem;
    font-size: .9rem;
    margin-bottom: 5rem;
    @include min-lg {
      border-left: 4px solid $color-orange;
      border-radius: 20px;
      padding-top: 2rem;
      padding-left: 5rem;
      margin-left: 5rem;
      border: none;
    }
    p {
      font-style: italic;
    }
  }

}
#clientsLogos {
  padding: 5rem 0;
  @include min-lg {
    padding: 7rem 0 5rem 0;
  }
}