.tabs-live {
  margin-top: 0;
  padding-top: 2rem;
}
.label__live {
  padding: 1rem 2rem;
  margin: .5rem;
  font-size: 1.1rem;
  font-weight: 700;
  transition: all .3s ease;
  &-proximo {
    background: #50C878!important;
    &:hover {
      background: #3da35f!important;
    }
  }
  &-anterior {
    &:hover {
      background-color: #EDEFF2;
    }
  }
}
.tab__content {
  &-live {
    width: 100%;
  }
}
.form-live {
  background: white;
  min-height: 470px;
  border-radius: .5rem;
  padding: 2rem;
  box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
  margin-top: 2rem;
  @include min-md {
    margin-top: -5rem;
  }
}
.video-live {
  margin-top: -5rem;
  margin-bottom: 2rem;
}
.img-live {
  width: 50%;
  @include min-md {
    width: 20%;
  }
}
.img-live-footer {
  width: 40%;
  @include min-md {
    width: 15%;
  }
}
.tp-live {
  .btn-live {
    text-align: center;
    transition: background ease 0.2s;
    border-radius: 5px;
    padding: 1rem 2rem;
    display: inline-block;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 2rem;
    @include min-md {
      margin-top: 0;
    }
    &:hover {
      color: white;
    }
    &-calendario {
      background-color: #018bcf;
      &:hover {
        background-color: #36b3e4;
      }
    }
    &-whatsapp {
      background-color: #50C878;
      &:hover {
        background-color: #84d8a0;
      }
    }
  }
}