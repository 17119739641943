/* montserrat-latin-wght-normal */
@font-face {
  font-family: 'Montserrat Variable';
  font-style:   normal;
  font-display: swap;
  font-weight:  100 900;
  src:          url(../fonts/montserrat/montserrat-latin-wght-normal.woff2) format('woff2-variations');
}

/* material-symbols-outlined-latin-wght-normal */
@font-face {
  font-family: 'Material Symbols Outlined Variable';
  font-style:   normal;
  font-display: swap;
  font-weight:  100 700;
  src:          url(../fonts/material-symbols-outlined/material-symbols-outlined-latin-wght-normal.woff2) format('woff2-variations');
}

$color-0:             #7C7C7C; // Cinza
$color-1:             #f2673c; //laranja
$color-1-lighter:     #FF8C08;
$color-1-lightest:    #FF8C08;
$color-1-light:       #FF8C08;
$color-1-dark:        #FE5F21;
$color-1-darkest:     #c33500;
$color-1-darker:      #FF6300;
$color-dark:          #212a34;
$color-bright:        #f8f9fa;
$color-orange:        #f2673c;
$color-orange-darker: #d95c36;
$color-2:             #1EB55C; // Verde
$color-success:       #5cb85c; // Verde

$font-1:      Arial, sans-serif;
$font-2:     'Montserrat Variable', sans-serif;
$font-3:     'Montserrat Variable', sans-serif;
$font-icons: 'Material Symbols Outlined Variable', sans-serif;

$box-shadow:       5px 10px 20px 0 rgba(0,0,0,.07);
$box-shadow-small: 5px 5px 10px -5px rgba(0,0,0,0.05);
$transition: all   .3s ease;
$imagePath :       "../../images";

/* novo sistema de cores - trocar gradualmente por este */
$orange:  #f2673c;
$black:   #212a34;
$light:   #f8f9fa;
$lighter: #d5dce2;
$white:   #fff;
$red:     #dc3545;
$green:   #28a745;