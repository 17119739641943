.row-promo {
  text-align: center;
  border-radius: 10px;
  &:hover {
    filter: brightness(1.02);
  }
}

.cta-banner-home {
  text-align: center;
  &__btn {
    a {
      background-color: $orange;
      display: inline-block;
      border-radius: 5px;
      box-shadow: 10px 10px 28px 0px rgba(0,0,0,0.2);
      padding: 15px;
      color: $black;
      width: 100%;
      margin: 10px 0;
      transition: all .3s ease;
      @include min-lg {
        margin: 20px 0;
        padding: 20px;
      }
      &:hover {
        transform: scale(.95);
      }
    }
    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}

#cta-agenda {
  text-align: center;
  .cta-agenda {
    &__box {
      padding: 40px 0;
      margin-bottom: 5rem;
      @include min-lg {
        margin-bottom: 5rem;
      }
      margin-left: 0;
      margin-right: 0;
      background: rgb(255,115,0);
      background: linear-gradient(90deg, rgba(255,115,0,1) 0%, rgba(255,78,0,1) 100%);
      @include min-lg{
        background: url(#{$imagePath}/layout/cta-home/img-agendamento.webp) no-repeat 110% 50%, linear-gradient(90deg, rgba(255,115,0,1) 0%, rgba(255,78,0,1) 100%);
      }
      border-radius: 20px;
      box-shadow: 10px 10px 28px 0px rgba(0,0,0,0.2);
      h2 {
        color: #fff;
        margin-bottom: 24px;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 400;
        span {
          font-weight: 700;
        }
      }
      &-btn {
        a {
          display: inline-block;
          align-items: center;
          background-color: #fff;
          border-radius: 50px;
          color: $color-dark;
          padding: 15px 20px;
          @include min-lg {
            padding: 15px 45px;
          }
          //box-shadow: 5px 5px 14px 0px rgba(0,0,0,0.1);
          transition: all .3s ease;
          &:hover {
            transform: scale(.95);
          }
        }
        &--content {
          display: flex;
          align-items: center;
          p {
            margin: 0;
            font-weight: 500;
            margin-left: 10px;
          }
        }
      }
    }
  }
}

/*#cta-promo {
  @include max-sm {
    margin: 2rem 0;
  }
  .row-promo {
    display: flex;
    align-items: stretch;
    @include max-sm {
      flex-direction: column;
    }
  }
  .box-promo {
    background: #f2673c;
    border-radius: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    @include min-lg {
      width: 50%;
    }
    p {
      color: $color-dark;
      font-size: 1.2rem;
      margin: 2rem 0;
      line-height: 1.2;
    }
    a {
      background-color: #fff;
      padding: 1rem 2rem;
      border-radius: 5rem;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      @include min-lg {
        font-size: 1.1rem;
        padding: 1rem 3rem;
      }
      &:hover {
        background-color: #ede7db;
      }
    }
  }
}
*/

#cta-especialista {
  background: linear-gradient(#fff 50%, $color-dark 0);
  @include max-md {
    padding: 50px 0;
  }
  @include min-xxl {
    margin-top: 200px;
  }
  text-align: left;
  .cta-especialista {
    &__box {
      padding: 40px 10px;
      margin: 0;
      background: rgb(255,115,0);
      background: linear-gradient(90deg, rgba(255,115,0,1) 0%, rgba(255,78,0,1) 100%);
      @include min-lg{
        background: url(#{$imagePath}/layout/cta-home/img-especialista.webp) no-repeat center right, linear-gradient(90deg, rgba(255,115,0,1) 0%, rgba(255,78,0,1) 100%);
        margin: 0 4rem;
        padding: 40px;
      }
      border-radius: 20px;
      box-shadow: 10px 10px 28px 0px rgba(0,0,0,0.2);
      h2 {
        color: #fff;
        font-size: 2rem;
        font-weight: 700;
      }
      p {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        font-weight: 400;
        @include min-lg {
          padding-right: 4rem;
          font-size: 1rem;
        }
      }
      &-btn {
        a {
          display: inline-block;
          align-items: center;
          background-color: #fff;
          border-radius: 50px;
          padding: 15px 20px;
          @include min-lg {
            padding: 15px 45px;
          }
          //box-shadow: 5px 5px 14px 0px rgba(0,0,0,0.1);
          transition: all .3s ease;
          &:hover {
            transform: scale(.95);
          }
        }
        &--content {
          display: flex;
          align-items: center;
          p {
            margin: 0;
            font-size: 1rem;
            font-weight: 500;
            margin-left: 10px;
            color: $color-dark;
            padding: 0;
          }
        }
      }
    }
  }
}