/* font sizes */
.f {
  &-size {
    &-1{font-size: 1.25rem;}
    &-2{font-size: 1.5rem;}
    &-3{font-size: min(calc(1.1rem + 1vw), 1.75rem);}
    &-4{font-size: 2rem;}
    &-5{font-size: min(calc(1.7rem + 1vw), 2.5rem);}
    &-6{font-size: 2.75rem;}
    &-7{font-size: min(calc(2rem + 1vw), 3rem);}
    &-8{font-size: min(calc(2.1rem + 1vw), 3.5rem);}
    &-9{font-size: min(calc(2.4rem + 1vw), 3.75rem);}
    &-10{font-size: min(calc(3.75rem + 1vw), 4rem);}
  }
  &-color {
    &-white {color:$white;}
    &-black {color:$black;}
    &-orange{color:$orange;}
  }
  &-weight {
    &-1{font-weight: 100;}
    &-2{font-weight: 200;}
    &-3{font-weight: 300;}
    &-4{font-weight: 400;}
    &-5{font-weight: 500;}
    &-6{font-weight: 600;}
    &-7{font-weight: 700;}
    &-8{font-weight: 800;}
    &-9{font-weight: 900;}
  }
  &-italic{font-style: italic;}
  &-bold  {font-weight: 700;}
  &-underline  {text-decoration: underline;}
}

.text {
  &-bg {
    padding: 0.4rem 0.75rem;
    display: inline;
    border-radius: .25rem;
    &-orange {
    background: $orange;
    color: $white;
  }
    &-black {
      background: $black;
      color: $white;
    }
  }
}