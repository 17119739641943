#PaginasFundo {
  .BannerHeader--lp {
    .img-lp-logo {
      max-width: 50%;
    }
    .BannerHeader__title {
      color: $color-orange;
      @include min-lg {
        font-size: 3rem;
      }
    }
    .BannerHeader__subtitle {
      color: #fff;
      font-size: 1.2rem;
      @include min-lg {
        font-size: 1.5rem;
      }
    }
  }
  #lp-items {
    list-style: none;
    margin: 0;
    @include min-lg {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
    li {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 1.5rem 1rem;
      box-shadow: 12px 12px 30px -15px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      border: solid 1px #edeff2;
      margin-bottom: 1rem;
      .svg-item {
        width: 28px;
        height: 28px;
        margin-right: .7rem;
      }
      svg {
        color: $color-orange;
      }
      p {
        margin: 0;
      }
      @include min-lg {
        width: 22.2%;
        margin: .4rem;
      }
    }
    li.item {
      @include min-lg {
        width: 32%;
      }
      &-check {
        padding-left: 2.75rem;
        &::before {
          font-family: $font-icons;
          content: '\e5ca';
          left: 0;
          margin-left: 1rem;
          font-size: 1.4rem;
          position: absolute;
        }
      }
    }
  }
}
#cta-banner-lp {
  background-color: $color-orange;
  margin-top: 5rem;
  @include min-lg {
    margin-top: 10rem;
  }
}
.footer-lp {
  background-color: $color-dark;
  padding: 2.4rem 0;
  margin-top: 4rem;
  text-align: center;
  .footer-bottom__logo {
    margin: 0 auto;
    @include min-lg {
      width: 15%;
    }
    margin-bottom: 1.4rem;
  }
}

/* landing page indique e ganhe */

  .indique {
    &__title {
      font-size: 1.75rem;
      font-weight: 900;
      @include min-lg {
        font-size: 2.5rem;
      }
      &_span-orange {
        background: $orange;
        color: #fff;
        padding: 0 1rem;
      }
      &_span-white {
        background: #fff;
        color: $orange;
        padding: 0 1rem;
      }
    }
    &__banner {
        background-image: url(#{$imagePath}/layout/indique-ganhe/banner-indique-e-ganhe-webmais.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        h1 {
          font-size: 1.2rem;
          @include min-lg {
            font-size: 1.5rem;
          }
        }
      &_img {
        @include max-sm {
          margin: 0 auto;
          width: 50%;
        }
      }
    }
    &__vantagens {
      background-color: #fefaf9;
      &_box {
        //background-color: #fff;
        border-radius: 1rem;
        //border: 1px solid #edeff2;
        padding: 2rem;
        //box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
        img {
          margin: 0 auto;
        }
        p {
          margin-top: 1rem;
        }
      }
    }
    &__cta {
      background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(254,250,249,1) 50%);
      //background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(254,248,247,1) 50%);
      &-box {
        background: #fdefeb;
        border-radius: 2rem;
        padding: 3rem 2rem;
        text-align: center;
      }
    }
    &__quem {
      &-box {
        background: #fefaf9;
        box-shadow: 20px 20px 40px 0px rgba(0,0,0,0.05);
      }
      &-img {
        width: 70%;
        @include min-lg {
          width: 50%;
        }
      }
    }
    &__qualificados {
      padding-top: 5rem;
      //background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(248,249,250,1) 50%);
      background: linear-gradient(0deg, rgba(255,255,255,1) 28%, rgba(242,103,60,1) 28%);
    }
  }