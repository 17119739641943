.cta-banner {
  //padding: 7rem 0;
  color: $color-dark;
  @include min-lg {
    &-gradient {
      background: rgb(248,249,250);
      background: linear-gradient(0deg, rgba(248,249,250,1) 50%, rgba(255,255,255,1) 50%);
    }
  }
  @include max-sm {
    padding: 5rem 0;
    background-color: $color-orange;
  }
  h2 {
    font-size: 2rem;
    //color: $color-orange;
    font-weight: 700;
    margin-bottom: 2rem;
    line-height: 1.2;
    span {
      color: $color-orange;
      font-weight: 700;
    }
  }
  .cta-banner-box {
    @include min-md {
      border-radius: 10px;
      background: url(#{$imagePath}/layout/background-vetor-black.svg), $color-orange;
      background-repeat: no-repeat;
      background-position: right center;
      padding: 5rem 7rem;
    }
  }
  .btn-cta {
    font-size: 1.2rem;
    padding: 15px 24px;
  }
}