.main {
  min-height: 550px;
  padding-top: 40px;
  padding-bottom: 40px;
  @include min-lg {
    padding: 0;
  }
}

.flag {
  display: inline;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.block-integracoes-img,
.block-erp-webmais-img,
.block-forca-venda-img {
  display: none;
}

@media only screen and (min-width:992px) {

  .block-info__content {
      margin-bottom: 30px;
  }
  .block-segmentos {
      background-image: url(#{$imagePath}/layout/segmentos-bg.svg);
      background-repeat: no-repeat;
      background-position: center 200px;
      padding: 150px 100px;
  }
  .block-ideal {
      background-image: url('../images/ideal-bg.jpg');
      background-repeat: no-repeat;
      background-position: 0 0;
      height: 900px;
  }
  .block-erp-webmais .icon {
      margin-bottom: 20px;
  }
  .block-info {
      padding: 20px 0;
  }
  .block-erp-webmais,
  .block-forca-venda {
      background-size: auto;
  }
  .block-integracoes {
    //background-image: url(#{$imagePath}/layout/integracoes.webp);
    //background-repeat: no-repeat;
    //background-position: right center;
    //background-size: 48%;
    height: 750px;
    position: relative;
    &-img {
      position: absolute;
      right: 0;
      width: 48%;
      display: block;
    }
  }
  .block-erp-webmais {
    //background-image: url(#{$imagePath}/layout/erp-webmais.webp);
    //background-repeat: no-repeat;
    //background-position: 0 center;
    height: 900px;
    position: relative;
    &-img {
      position: absolute;
      left: 0;
      display: block;
    }
  }
  .block-forca-venda {
    //background-image: url(#{$imagePath}/layout/forca-venda.webp);
    //background-repeat: no-repeat;
    //background-position: right center;
    height: 900px;
    background-size: 44%;
    &-img {
      position: absolute;
      right: 0;
      width: 44%;
      display: block;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.section {
  padding-top: 20px; 
  padding-bottom: 20px;
  &.is-first {
      @include min-lg {
          padding-top: 20px;
      }
  }
  @include min-lg {
      padding-top: 50px; 
      padding-bottom: 50px;
  }
  &__actions {
      display: block;
      clear: both;
      margin-top: 30px;
      margin-bottom: 50px;
  }
}
.block-info {
  display: flex;
  align-items: center;
  padding: 30px 0;
  .figure {
    max-width: 70%;
    margin: 0 auto 30px;
    @include min-lg {
        float: left;
        max-width: 100%;
    }
  }
  &--right {
    .figure {
      @include min-lg {
        float: right;
      }
    }
  }
}
.testimony {
  &-container {
    @extend .section;
  }
  &__header {
    text-align: center;
    display: block;
    margin-bottom: 40px;       
  }
  &__title {
    font-size: 2rem;
    font-weight: 900;
    line-height: 1.2;
    font-family: $font-2;
    margin-bottom: 10px;
    @include min-lg {
      font-size: 2.4rem;
    }
  }
  &__subtitle {
    font-size: 1.2rem;
    line-height: 1.2;
    font-family: $font-2;
    margin-bottom: 10px;
    @include min-lg {
      font-size: 1.4rem;
    }
  }
}
.clients {
  &-container {
    //display: none;
    background: $light;
    padding: 70px 0;
    //box-shadow: 0 2px 6px #eee;
  }
}

.planos-coluna a {
  font-style: italic;
  color: #e48612
}