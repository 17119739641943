.bn-card {
  background-color: #3c4855;
  //background-color: $orange;
  //border: 2px solid #1d2a35;
  box-shadow: 0 0 40px 5px rgba(242, 103, 60, .5);
  padding: 2rem;
  @include min-lg {
    padding: 3rem;
  }
  border-radius: 2rem;
  margin-bottom: 2rem;
  &:hover {
    box-shadow: 0 0 40px 15px rgba(242, 103, 60, .5);
    transition: ease .2s;
  }
  p {
    font-size: 1.1rem;
  }
}
.bn-cta {
  background: rgb(33,42,52);
  //background: linear-gradient(180deg, rgba(33,42,52,1) 0%, rgba(52,63,73,1) 40%);
  background: linear-gradient(180deg, rgba(33,42,52,1) 20%, rgba(242,103,60,1) 100%);
  h2 {
    font-size: 2rem;
    font-weight: 900;
    line-height: 1.2;
    color: $white;
    @include min-lg {
      font-size: 3.5rem;
    }
  }
  img {
    filter: drop-shadow(10px 8px 10px rgba(0, 0, 0, .2));
  }
  p {
    font-size: 1rem;
    @include min-lg {
      font-size: 1.28rem;
    }
  }
}