.embed-youtube {
    background-color: #000;
    border-radius: 1rem;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
    img {
        width: 100%;
        top: -16.84%;
        left: 0;
        opacity: .7;
        cursor: pointer;
        position: absolute;
    }
    .embed-youtube-play {
        width: 68px;
        height: 48px;
        background-color: #333;
        box-shadow: 0 0 30px rgba(0, 0, 0, .6);
        z-index: 1;
        opacity: .8;
        border-radius: 6px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        &:before {
            content: "";
            border-style: solid;
            border-width: 15px 0 15px 26px;
            border-color: transparent transparent transparent #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
        &:hover {
            background-color: red;
        }
    }
    iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}
