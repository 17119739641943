.features {
  &-item {
    text-align: center;
    &--box {
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #f8f9fa;
      padding: 2rem;
      transition: all .2s ease;
      box-shadow: 10px 10px 32px -7px rgba(0,0,0,0.05);
      &:hover {
        box-shadow: 10px 10px 32px -7px rgba(0,0,0,0.1);
      }
      h2 {
        font-size: 1rem;
        font-weight: 600;
        margin: 1rem 0;
        line-height: 1.2;
      }
      p {
        font-size: .9rem;
      }
      a {
        font-size: 1.15;
        font-weight: 700;
        text-decoration: underline;
        color: #96381B;
      }
      img {
        width: 40%;
        margin: 0 auto;
      }
      &-orange {
        background-color: $orange;
      }
      &-link {
        color: #fff;
      }
    }
  }
}
@include min-lg {
  .features:before,
  .features:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 200px;
    top: 0;
    height: 100%;
    pointer-events: none; //makes the links behind clickable.
  }
  .features:before {
    left: 0;
    background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  }
  .features:after {
    right: 0;
    background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
  }
}