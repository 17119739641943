#breadcrumb { 
  font-weight: 700;
  font-size: .75rem;
  margin-bottom: 1rem;
  @include min-lg {
    margin-bottom: 2rem;
  }
  ol li {
    display: inline;
    color: $orange;
    &::after {
      content: '>';
      margin-right: 5px;
    }
    &:last-child::after {
      content: '';
    }
    a {
      color: $orange;
      margin-right: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}