/******Atenção*****/

/*Novos padrões - trocar o nome do arquivo depois*/
/*este arquivo será utilizado para estilos pontuais. Este, por exemplo, é para a página de produtos*/

/*Página Indústria*/
.banner-header {
  &__image {
    display: none;
      @include min-md {
      display: block;
      margin-top: 0;
      position: absolute;
      top: 2rem;
      right: 0;
      max-width: 50%;
      z-index: 2;
    }
  }
}

//card
.card {
  &__custom {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: $black;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    &-img {
      border-radius: 1rem;
      width: 100%;
      }
    &-img-overlay {
      left: 0;
      padding: 1rem;
      position: absolute;
      right: 0;
      @include min-lg {
        padding: 4rem;
        bottom: 0;
      }
    }
    &-link {
      font-weight: 700;
      color: $orange;
      text-decoration: underline;
    }
  }
}

//sticky image scroll
.content {
  position: relative;
  &__image {
    position: sticky;
    top: 10rem;
    //overflow: hidden;
    box-shadow: $box-shadow;
    border-radius: .25rem;
    img {
      width: 100%;
    }
  }
}

/*fim novos padrões*/

#PaginasFundo {
  font-family: $font-3;
  color: $color-dark;
  strong {
    font-weight: 600;
  }
  .container-logos-clientes {
    padding: 0 2rem;
    img {
      border-radius: 5px;
      box-shadow: 7px 7px 15px 0px rgba(0,0,0,0.05);
      margin-bottom: 20px;
    }
  }

  /*--conteudo--*/
  .conteudo {
    .row-margin {
      margin-top: 7rem;
      margin-bottom: 7rem;
      @include max-sm {
        margin-top: 4rem;
        margin-bottom: 4rem;
      }
    }

    //espaçamento entre os blocos de texto e imagem
    &-container {
      &--odd {
        @include min-sm {
          padding-right: 2rem;
        }
      }
      &--even {
        @include min-sm {
          padding-left: 2rem;
        }
      }
    }

    //estilo de textos dos blocos de texto
    &-title {
      font-weight: 900;
      font-size: 1.8rem;
      line-height: 1.2em;
      position: relative;
      &::after {
        content: "";
        border-bottom: 4px solid $color-orange;
        bottom: -28px;
        display: inline-block;
        left: 0;
        position: absolute;
        width: 100px;
      }
      &-big {
        font-size: 2.5rem;
        &::after {
          display: none;
        }
      }
    }
    &-description {
      font-weight: 400;
      //font-size: 1rem;
      line-height: 1.8em;
      margin: 3rem 0 2rem 0;
    }
    &-img {
      @include max-sm {
        margin-top: 4rem;
      } 
    }
    ul {
      list-style: disc;
      //list-style-position: inside;
      margin-left: 1rem;
      margin-top: 1rem;
      li {
        margin-bottom: 1rem;
        &::marker{
          color: #ff5f00;
          
        }
        h3 {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }

    //botões das funcionalidades
    &-background {
      background: url(#{$imagePath}/layout/pattern.png) repeat transparent;
      //background-color: #ff5f00;
      padding: 5rem 0;
      &--transparent {
        background: none;
      }
      h2.background-title {
        margin: 0;
      }
      p.background-text {
        margin: 1rem 0 3rem 0;
        @include min-lg {
          padding: 0 8rem;
          text-align: center;
        }
      }
      h2 {
        font-size: 1.7rem;
        font-weight: 800;
        margin-bottom: 2.5rem;
        line-height: 1.2;
        @include min-lg {
          text-align: center;
        }
      }
      .col-margin {  
        @include max-sm {
          margin-bottom: 1rem;
        }
      }
      .conteudo-box-funcionalidades {
        display: flex;
        @include min-lg {
          justify-content: center;
        }
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 7px 7px 15px 0px rgba(0,0,0,0.08);
        padding: 2rem;
        transition-duration: .3s;
        &:hover {
          transform: translateY(-10px);
          background-color: $color-bright;
        }
        img {
          margin-right: 1rem;
        }
        h3 {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 7px;
          line-height: 1.4;
        }
        p {
          font-size: .85rem;
          line-height: 1.4;
        }
      }
    }
    //sessão benefícios do erp
    &-benef {
      margin-top: 5rem;
      margin-bottom: 5rem;
      @include min-lg {
        margin-top: 10rem;
        margin-bottom: 8rem;
      }
      h2 {
        font-size: 1.7rem;
        font-weight: 900;
        line-height: 1.2;
        margin-bottom: 2.5rem;
      }
      ul.list-benef {
        list-style: none;
        li {
          background-color: $color-bright;
          padding: 2rem;
          margin-bottom: 1rem;
          border-radius: 10px;
          @include min-lg {
            margin: 0 1rem 2rem 1rem;
          }
          &:last-child {
            margin-bottom: 0;
          }
          h3 {
            font-size: 1.2rem;
            font-weight: 700;
            margin-bottom: 1rem;
          }
        }
      }
      @include min-lg {
        .text-benef.sticky-text-benef {
          position: sticky;
          top: 20vh;
        }
      }
    }
    &__cta {
      @include min-lg {
        background-image: url(#{$imagePath}/layout/background-vetor-orange.svg);
        background-repeat: no-repeat;
        background-position: 110%;
        text-align: center;
      }
      background-color: $color-dark;
      padding: 5rem 0;
      margin: 2rem 0;
      text-align: left;
      &-title {
        color: #fff;
        font-size: 1.75rem;
        @include min-lg {
          font-size: 2rem;
        }
        font-weight: 700;
        margin-bottom: 2rem;
      }
      &-btn {
        font-size: 1.2rem;
        border: 1px solid $color-dark;
        &:hover {
          background: #fff;
          border-color: #fff;
        }
      }
    }
  }
  .clients-background {
    padding: 5rem 0;
  }

  //estilo dos títulos centralizados
  .center-title {
    text-align: center;
    margin-bottom: 3rem;
    @include min-lg {
      margin-bottom: 5rem;
    }
    h2 {
      font-size: 1.7rem;
      font-weight: 800;
      line-height: 1.2;
      span {
        color: $color-orange;
      }
    }
    p {
      margin: 1rem 0 3rem 0;
      @include min-lg {
        padding: 0 8rem;
        text-align: center;
      }
    }
  }

  //recursos
  .recursos-box {
    padding: 2rem;
    h3 {
      font-size: 1.4rem;
      font-weight: 700;
      margin: 1rem 0;
    }
  }  

  //recursos
  .section-recursos {
    margin: 5rem 0;
  }

  //testimonials
  #erpTestimonials {
    padding-top: 5rem;
    @include min-lg {
      padding-top: 7rem;
    }
    .erp-testimonials-box, .erp-testimonials-box-reverse {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include min-lg {
        flex-direction: row;
        align-items: center;
      }
      img {
        width: 100px;
        height: 100px;
      }
    }
    .erp-testimonials-box {
      @include max-sm {
        margin-top: 4rem;
      }
    }
    .erp-testimonials-box-reverse {
      margin-top: 2.5rem;
      @include min-lg {
        flex-direction: row-reverse;
      }
    }
    .erp-testimonials-text {
      @include min-lg {
        padding: 0 2rem;
      }
      p {
        @include min-lg {
          margin-top: 0;
        }
        margin-top: 2rem;
        font-style: italic;
        line-height: 1.2;
      }
    }
    p.erp-testimonials-name {
      font-style: normal;
      margin-top: 0;
      span {
        font-weight: 700;
        color: $color-orange;
      }
    }
  }
}