 #IndiqueGanhe {
  font-family: $font-3;
  color: $color-dark;
  strong {
    font-weight: 600;
  }
  .BannerHeader {
    //background-color: #2b2149;
    background: linear-gradient(195deg, rgba(43,33,73,1) 0%, rgba(75,59,125,1) 100%);
    color: $white;
    padding: 2rem 0;
    margin-bottom: 0;
    @include min-lg {
      background: url(#{$imagePath}/layout/logo-background.svg) no-repeat top right, linear-gradient(195deg, rgba(43,33,73,1) 0%, rgba(75,59,125,1) 100%);
      height: 95vh;
    }
    img {
      margin-bottom: 2rem;
    }
    &__title {
      font-size: 2.4rem;
      font-family: $font-3;
      @include min-lg {
        font-size: 3rem;
      }
    }
    &__subtitle {
      font-size: 1.2rem;
      strong {
        color: $color-orange;
      }
      @include min-lg {
        font-size: 1.4rem;
      }
    }
  }
  .conteudo {
    text-align: center;
    margin: 4rem 0;
    &-title--indique {
      font-size: 1.7rem;
      font-weight: 900;
      line-height: 1.2em;
      margin: 1rem 0;
      @include min-lg {
        font-size: 2.1rem;
        margin: 2rem 0 3rem 0;
      }
    }
  }
  ul.lista-indique {
    list-style: none;
    display: flex;
    flex-direction: column;
    @include min-lg {
      flex-direction: row;
    }
    li {
      flex: 1;
      text-align: center;
      img {
        margin: 0 auto 20px auto;
      }
      h3 {
        font-weight: 800;
        font-size: 1.5rem;
        color: $color-orange;
      }
      &.lista-indique--passos {   
        padding: 1rem;
        margin: 1rem;
      }
      &.lista-indique--item {   
        //border: 1px solid #ddd;
        background-color: #fff;
        box-shadow: 5px 10px 20px 0 rgb(0 0 0 / 5%);
        border-radius: 10px;
        padding: 1rem;
        margin: 1rem;
        p {
          font-weight: 600;
        }
      }
    }
  }
  .valores {
    background: url(#{$imagePath}/layout/pattern.png) repeat transparent;
    &-padding {
      padding: 4rem 0;
    }
  }
  .cta-indique {
    &--box {
      background: linear-gradient(195deg, rgba(255,115,0,1) 0%, rgba(255,78,0,1) 100%);
      border-radius: 15px;
      box-shadow: 5px 10px 20px 0 rgb(0 0 0 / 10%);
      text-align: center;
      padding: 2rem 1rem;
      margin: 0;
      @include min-lg {
        background: url(#{$imagePath}/layout/background-cta.svg) no-repeat -28% 50%, linear-gradient(195deg, rgba(255,115,0,1) 0%, rgba(255,78,0,1) 100%);
        padding: 2rem 3rem;
      }
      h2 {
        text-align: left;
        span {
          color: #fff;
          font-weight: 400;
        }
      }
      &-btn {
        background-color: #fff;
        transition: all .3s ease;
        display: inline-block;
        margin: 0 auto;
        font-size: 1rem;
        text-align: center;
        text-transform: uppercase;
        color: $color-dark;
        font-weight: 700;
        border-radius: 50px;
        padding: 10px 24px;
        @include min-lg {
          padding: 15px 30px;
          font-size: 1.1rem;
        }
        &:hover {
          background-color: $color-success;
          color: white;
        }
      }
    }
  }
  .footer-indique {
    background-color: $color-bright;
    margin-top: 8rem;
    padding: 2rem 0;
    img {
      margin: 0 auto;
      width: 175px;
    }
  }
  .lista-funcionalidades {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 100%;
      margin: 1rem;
      padding: 1rem;
      @include min-lg {
        width: 28%;
        margin-bottom: 2rem;
      }
      img {
        margin: 0 auto;
        width: 120px;
        height: auto;
      }
      h3 {
        margin: 1rem 0;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }
}