html {
  scroll-behavior: auto;
}
body {
  font-family: $font-2;
  font-size: 15px;
  color: $black;
  line-height: 1.4;
  min-width: 300px;
  background: #fff; 
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #ededed;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $orange;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
}
::selection {
  color: #fff;
  background-color: $orange;
}
* {
    &::before,
    &::after {
      box-sizing: border-box;
    }
    &:focus {
      outline: none;
    }
}
input {
    &[type='search'] {
      box-sizing: border-box;
    }
}
p {
  margin: 0 0 10px;
}
img {
  display: inherit;
  max-width: 100%;
}
header {
  position: relative
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.aspect-ratio {
  aspect-ratio: auto 640 / 360;
}
a {
  color: $black;
  &:hover {
    text-decoration: none;
    color: #f8af98;
  }
}