.u-fixLightSlider:not(.lightSlider) {
	img {
		position: absolute;
		top: 0;
		left: 0; 
		display: none;
	}
}
.u-fixSlick:not(.slick-initialized) {
	img {
		position: absolute;
		top: 0;
		left: 0; 
		display: none;
	}
}
.sticky-wrapper {
	//height: 100%;
	//height: 85px;
    /*@include min-lg {
    	height: 100px!important;
    }*/
    background: $orange; 
}