/*--estilo global para os banners - trocar os outros para este, gradativamente--*/
.banner-header {
  position: relative;
  background-color: $color-dark;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include min-md {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }
  color: #fff;
  &__background {
    @include min-md {
      background-image: url(../../images/layout/new-background.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__title {
    color: $orange;
    font-size: min(calc(1rem + 1vw), 1.4rem);
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &__subtitle {
    font-size: min(calc(1.425rem + 2.1vw), 2.5rem);
    //font-size: min(calc(1.2rem + 1vw), 2.5rem);
    color: $white;
    font-weight: 900;
    margin-bottom: 2rem;
  }
  &__description {
    color: $white;
    font-size: 400;
  }
  &__bottom {
    position: absolute;
    bottom: -1px;
    left: 0;
    overflow: hidden;
    @include max-sm {
      display: none;
    }
    &-arrow {
      animation: bounce 2s ease-in-out forwards;
      animation-iteration-count: infinite;
      @keyframes bounce {
        0% { transform: translateY(0px);}
        50% {transform: translateY(5px);}
      }
    }
  }
}

/*--banners das novas páginas--*/
#PaginasFundo {
  .BannerHeader {
    background-color: $color-bright;
    color: $color-dark;
    margin-bottom: 2rem;
    @include min-lg {
      //padding: 2rem 0;
      margin-bottom: 0;
      background-image: url(#{$imagePath}/layout/new-background-light.svg);
    }
    &__title {
      span {
        font-weight: 500;
      }
      font-family: $font-3;
      font-size: 2rem;
      &--segmentos {
        color: #fff;
        @include min-lg {
          padding-right: 2rem;
          font-size: 2.5rem;
        }
      }
      &--keyword {
        color: $color-orange;
        font-size: 1rem;
        font-weight: 600;
        @include min-lg {
          padding-right: 2rem;
          font-size: 1.2rem;
        }
      }
    }
    &__subtitle {
      font-size: 1.1rem;
      font-weight: 400;
      margin-top: 2rem;
      &--segmentos {
        color: #fff;
        @include min-lg {
          padding-right: 2rem;
        };
      }
      &--contato {
        color: #fff;
        font-weight: 600;
        font-size: 1.5rem;
        @include min-lg {
          padding-right: 2rem;
        }
      }
      @include max-sm {
        font-size: 1rem;
      }
    }
    &__form {
      min-height: unset;
      max-width: 540px;
    }
    @include min-xxl {
      height: unset;
      padding-top: 0;
      padding-bottom: 100px;
    }
    @include min-lg {
      &--relative-header {
        padding-top: 50px;
      }
    }
    .embed-youtube {
      @include max-sm {
        margin-top: 2rem;
      }
    }
    &--segmentos {
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      padding-bottom: 5rem;
      margin-bottom: 0;
      position: relative;
      background-color: $black;
      @include min-lg {
        height: 85vh;
        padding-bottom: 0;
        background-size: cover;
      }
      @include min-xxl {
        height: 65vh;
      }
    }
    &--trabalhe {
      position: relative;
      background-position: center;
      background-repeat: repeat;
      background-size: cover;
      background-color: $color-dark;
      position: relative;
      padding-bottom: 7.5rem;
      padding-top: 2rem;
      @include min-lg {
        background-image: url(#{$imagePath}/layout/new-background.svg);
        padding-top: 6rem;
      }
    }
    &--contato {
      background-color: $color-dark;
      background-position: center;
      background-repeat: repeat;
      background-size: cover;
      padding-bottom: 5rem;
      position: relative;
      margin-bottom: 0;
      p {
        color: #fff;
      }
      @include min-lg {
        padding-top: 10rem;
        background-image: url(#{$imagePath}/layout/new-background.svg);
      }
      @include min-xxl {
        height: 90vh;
      }
    }
    &--wms {
      background-color: $color-dark;
      @include min-lg {
        background-image: url(#{$imagePath}/layout/new-background.svg);
        padding-top: 100px;
        padding-bottom: 121px;
      }
      @include max-sm {
        padding-bottom: 5rem;
      }
      background-position: center;
      background-repeat: repeat;
      background-size: cover;
      margin-bottom: 0;
    }
    &--lp {
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 90vh;
      @include min-lg {
        min-height:inherit;
        padding-top: 8rem;
        padding-bottom: 8rem;
      }
    }
    .arrow-down {
      position: absolute;
      right: 45%;
      bottom: -5rem;
      z-index: 10;
      animation: bounce 2s ease-in-out forwards;
      animation-iteration-count: infinite;
      @include min-lg {
        top: 2rem;
        right: 50%;
      }
    }
    @keyframes bounce {
      0% {
        transform: translateY(0px);
      }
      50% {
        transform: translateY(20px);
      }
    }

    .arrow-down-trabalhe {
      @extend .arrow-down;
      @include min-lg {
        bottom: -4rem;
        right: 50%;
      }
    }

    .arrow-down-contato {
      @extend .arrow-down;
      @include min-lg {
        bottom: -4rem;
        right: 50%;
      }
    }

    .arrow-down-wms {
      @extend .arrow-down;
      @include min-lg {
        bottom: -8rem;
        right: 50%;
      }
      @include max-sm {
        bottom: -5rem;
      }
    }
  }
}

/*--banners das páginas antigas--*/
.BannerHeader {
    @extend .section;
    color: #fff;
    background: $black;
    padding-bottom: 5rem;
    margin-bottom: 5rem;
    @include min-lg {
      position: relative;
      display: flex;
      align-items: center;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(#{$imagePath}/layout/banner-webmais-sistemas.webp);
      background-position: center;
      background-size: cover;
      margin-bottom: 70px;
      padding: 5rem 0;
    }
    &__center{
      display: flex;
      align-items: center;
    }
    &__hgroup {
      margin-top: -130px;
    }
    &__title {
      font-weight: 800;
      letter-spacing: -1px;
      line-height: 1.3;
      font-size: 2rem;
      @include min-lg {
        font-size: 2.7rem;
      }
    }
    &__subtitle {
      font-size: 1.2rem;
      font-weight: 300;
      line-height: 1.5;
      margin: 1rem 0;
      &--top {
        font-size: 24px;
      }
    }
    &__desc {
      max-width: none !important;
      margin-bottom: 30px;
      @include min-lg {
        max-width: 410px;
      }
    }
    &__picture {
      float: right;
      margin-top: 20px;
    }
    &__infobox {
      @include min-lg {
        display: inline-block;
        max-width: 700px;
        margin-top: 40px;
      }
    }
    &__bottom {
      position: absolute;
      bottom: -2px;
      left: 0;
      @include max-sm {
        display: none;
      }
      &-arrow {
        animation: bounce 2s ease-in-out forwards;
        animation-iteration-count: infinite;
        @keyframes bounce {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(5px);
          }
        }
      }
    }
    /*&__button-scroll {
      display: none;
      @include min-lg {
        display: block;
        margin: 0 auto;
        padding-top: 2rem;
        animation: bounce 2s ease-in-out forwards;
        animation-iteration-count: infinite;
        @keyframes bounce {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(20px);
          }
        }
      }
    }*/
    /*&__form {
        max-width: 505px;
    }*/
    .containerformp {
        margin: 50px 0;
        padding: 15px;
        border-radius: 10px;
        font-size: 17px;
        color: #222;
        background: #8fff82;
    }
}