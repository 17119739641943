.work-with-us {
  background-color: #212a34;
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 10px;
  @include min-lg {
    padding: 2rem;
    margin: 2rem;
  }
  label {
    color: #fff;
    font-size: .9rem;
    margin-bottom: .5rem;
  }
  #nome, #replyto, #linkedin, #vagas, #mensagem, #email, #name {
    width: 100%;
    border: none;
    border-radius: 10px;
    color: #7d7d7d;
    height: 45px;
    margin-bottom: 12px;
    padding-left: 20px;
  }
}

.work-with-us {
  #mensagem {
    height: 140px;
  }
}

.anexo {
  color: #fff;
  font-weight: bold;
  border: 2px solid;
  position: relative;
  text-align: center;
  border-radius: 10px;
  display: block;
  //transform: translateY(23px);
  z-index: 9;
  padding: 10px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #222;
  }
}

.submit-container {
  border-radius: 10px;
  border: 2px dashed #fff;
  padding: 2rem;
}

input[type=file] {
  width: 100%;
  border: none;
  color: #fff;
  margin-top: 1rem;
  font-size: .75rem;
  &::file-selector-button {
    background: #212a34;
    font-weight: 700;
    font-size: 1rem;
    border: solid 1px #fff;
    border-radius: 10px;
    color: #fff;
    transition: background .2s ease-in-out;
    cursor: pointer;
    padding: .4rem 1rem;
    &:hover {
      background: $color-orange;
      border-color: $color-orange;
    }
  }
}

#enviar {
  color: #fff;
  background: #5cb85c;
  border: 2px solid #5cb85c;
  position: relative;
  text-align: center;
  border-radius: 10px;
  display: block;
  width: 100%;
  margin-top: 2rem;
  //transform: translateY(23px);
  z-index: 9;
  padding: 10px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: darken(#5cb85c, 15%);
    border-color: darken(#5cb85c, 15%);
  }
}

.BannerHeader__image--segmentos {
  @include max-sm {
    display: none;
  }
  @include min-lg {
    position: absolute;
    right: 5%;
    bottom: 0;
  }
  @include min-xxl {
    right: 15%;
    bottom: 0;
  }
}

.background-title-trabalhe {
  margin-top: 5rem;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(248,249,250,1) 50%);
  @include min-lg {
    margin-top: 2rem;
  }
}

.title-trabalhe {
  font-size: 1.5rem;
  text-align: center;
  display: table;
  font-weight: 800;
  background: $color-orange;
  padding: 1rem 2rem;
  margin: 0 auto;
  box-shadow: 7px 7px 0px 0px rgba(0,0,0,0.75);
  @include min-lg {
    font-size: 2rem;
  }
}

.background-trabalhe {
  background-color: $color-bright;
}

#faq-trabalhe {
  .question {
    background: #fff!important;
    &:hover{
      background-color: $color-orange!important;
    }
  }
  .questions:checked ~ .question{
    background-color: $color-orange!important;
    color: #fff!important;
  }
  .answers {
    background: #fff!important;
  }
}

.background-banco-talentos {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(248,249,250,1) 50%);
  .row {
    background: url(#{$imagePath}/layout/trabalhe-conosco/banco-de-talentos.webp);
    background-repeat: no-repeat;
    background-position: center;
    padding: 4rem 1rem;
    @include min-lg {
      padding: 5rem;
      border-radius: 10px;
      box-shadow: 10px 10px 24px 1px rgba(0,0,0,0.23);
      background-size: 100%;
    }
  }
}

.form-trabalhe {
  padding: 4rem 0;
  background: url(#{$imagePath}/layout/background-vetor-black.svg);
  background-repeat: no-repeat;
  background-position: -20% center;
  @include min-lg {
    padding: 7rem 0;
    background-position: -45% center;
  }
  @include min-xxl {
    background-position: -15% center;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4;
    @include min-lg {
      padding: 0 2rem;
      font-size: 2rem;
    }
    span {
      color: $color-orange;
      font-weight: 800;
    }
  }
}

.benef {
  &__wrap {
    text-align: center;
    padding: 1rem;
    h3 {
      font-size: 1rem;
      font-weight: 700;
    }
    p {
      margin: 0;
      font-size: .9rem;
    }
  }
}

.background-historia {
  background-color: $color-dark;
  padding: 4rem 0;
  @include min-lg {  
    padding: 7rem 0;
    background-image: url(#{$imagePath}/layout/background-vetor-orange.svg);
    background-repeat: no-repeat;
    background-position: 100% center;
  }
  h2 {
    color: $color-orange;
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  p {
    color: #fff;
  }
}

.ceo {
  &__wrap {
    padding: 2rem;
    text-align: center;
    img {
      margin: 0 auto;
    }
    h3 {
      color: $color-orange;
      font-weight: 800;
      font-size: 1.5rem;
      margin-top: 2rem;
    }
    p {
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }
  &__link {
    svg {
      fill: $color-orange;
      &:hover {
        fill: #d95c36;
      }
    }
  }
}

.testimonials {
  &__background {
    //background: $color-bright;
    padding: 1rem;
    flex-direction: column;
    @include min-lg {
      padding: 2rem 5rem!important;
    }
    border-radius: 15px;
    text-align: center;
    img {
      width: initial;
    }
  }
  &__answer {
    font-style: italic;
    margin-top: 1.4rem;
  }
  &__name {
    font-size: 1rem;
    font-weight: 700;
  }
  &__setor {
    font-size: .90rem;
    font-style: italic;
  }
  &__year {
    font-weight: 500;
    margin: 0;
  }
}

.slick-slide {
  margin: 0 28px;
}

.background-about {
  margin: 7rem 0;
}

.wk-image {
  &-mosaic {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    grid-auto-rows: 1fr;
    margin-top: 4rem;
    @include min-md {
      padding: 2rem;
      margin-top: 0;
    }
    &-item {
      position: relative;
      padding-top: 100%;
    }
  }
}

.wk-image-mosaic-item > *{
  border-radius: .5rem;
  width: 100%;
  height: 100%;
  top:0;
  position: absolute;
  object-fit: cover;
  transition: $transition;
  &:hover {
    box-shadow: 0px 10px 15px -5px rgba(0,0,0,0.1);
    filter: brightness(75%);
  }
}

@include min-lg {
  .wk-image-mosaic {
  grid-template-columns: repeat(3, 1fr);
  }
  .wk-image-mosaic *:nth-child(3) {
    grid-column: span 2;
    grid-row: 2;
    padding-top: 50%;
  }
  .wk-image-mosaic *:nth-child(4) {
    grid-column: 3;
    grid-row: 1 / span 2;
    padding-top: 50%;
  }
}