.expansao {
  &-banner {
    @include min-md {
      padding-bottom: 15rem;
    }
  }
  &-cards {
    @include min-md {
      margin-top: -10rem;
      img {
        width: 70%;
      }
    }
  }
  &-acompanhamento {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include min-md {
      padding-top: 5rem;
      padding-bottom: 15rem;
    }
  }
  &-box {
    position: relative; 
    &-desconto {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #50C878 ;
      text-align: center;
      color: #fff;
      font-weight: 700;
      padding: .5rem;
      width: 100%;
      border-bottom-right-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
  }
}