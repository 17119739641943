#bigNumbers {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: $color-bright;
  h2 {
    font-size: 1.7rem;
    font-weight: 800;
    //color: $color-orange;
    line-height: 1.4;
    margin-bottom: 1rem;
    @include min-lg{
      margin-bottom: 0;
    }
  }
  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4;
    color: $color-dark;
  }
  .list-bubble-numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1.2rem;
    @include min-lg {
      margin-top: 2.8rem;
    }
    li {
      margin: 0 2rem;
      @include max-sm {
        width: 45%;
        margin: 1rem .5rem;
      }
      p {
        margin-top: 2rem;
      }
    }
    .bubble-numbers {
      position: relative;
      background: $color-orange;
      color: $color-dark;
      font-size: 2rem;
      font-weight: 800;
      text-align: center;
      width: 150px;
      margin: 0 auto;
      //height: 100px;
      border-radius: 20px;
      padding: 1rem;
      border: #fff solid 2px;
      @include max-sm {
        font-size: 2rem;
        border-radius: 10px;
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: $color-orange transparent;
        border-width: 20px 30px 0;
        bottom: -20px;
        left: 50%;
        margin-left: -30px;
      }
      &:before {
        content: '';
        position: absolute;
        width: 0;
        z-index: 0;
        border-style: solid;
        border-color: #fff transparent;
        border-width: 22px 32px 0;
        bottom: -23px;
        left: 50%;
        margin-left: -32px;
        display: block;
      }
    }
  }
}