.is-sticky {
	.header {
		z-index: 1000 !important;
		box-shadow: 0px -3px 32px #333;
		padding-top: 8px;
	  padding-bottom: 8px;
		background: $orange;
		@include min-lg {
			transition: ease height 0.3s;
		}
		&__logo {
			padding: 0;
			transition: ease all 0.4s;
			float: left;
			transform: scale(0.8);
			margin-left: -20px;
			@include min-lg {
				transform: scale(0.75);
				margin-left: -5%;
				position: relative;
				top: 2px;
			}
		}
	}
}
.sticky-wrapper {
	position: relative;
}
.header {
	z-index: 999;
	padding: 1rem 0;
	transition: ease padding 0.4s;
	background-color: $orange;
	&__logo {
		max-width: 200px;
		display: inline-block;
		margin: 0;
		transition: ease all 0.4s;
		&-container {
			display: flex;
			align-items: center;
			height: 100%;
		}
		> img {
			max-width: inherit;
			width: 100%;
      @include max-sm {
        width: 70%;
      }
		}
	}
	&__buttons {
		display: inline-flex;
		align-items: center;
		> li {
			display: flex;
			margin-right: 15px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&__nav {
		display: none;
		@include min-lg {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 100%;
		}
	}
	&__actions {
		display: flex;
		height: 100%;
		gap: 5px;
    justify-content: flex-end;
		align-items: center;
	}
}