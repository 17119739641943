#erpIntegrado {
  font-family: $font-3;
  color: $color-dark;
  strong {
    font-weight: 600;
  }
  .BannerHeader {
    background: $color-bright;
    color: $color-dark;
    margin-bottom: 2rem;
    @include max-sm {
      padding: 2rem 0;
      margin-bottom: 0;
    }
    &__title {
      span {
        font-weight: 500;
      }
      font-family: $font-3;
      font-size: 2.8rem;
    }
    &__subtitle {
      font-size: 1.2rem;
      font-weight: 400;
      margin: 2rem 0;
      @include max-sm {
        font-size: 1rem;
      }
    }
    &__form {
      min-height: unset;
      max-width: 540px;
    }
    @include min-lg {
      height: 95vh;
    }
  }

  .BannerHeader__image {
    position: absolute;
    top: 0;
    right: -5rem;
    height: auto;
    width: 58%;
    @include min-xxl {
      width: 48%;
      right: 0;
    }
    @include max-sm{
      display: none;
    }
  }

  //integrações
  .block-info {
    padding: 5rem 0;
    @include max-sm {
      padding: 4rem 0;
    }
    &--gray {
      background: $color-bright;
    }
    &--text {
      h2 {
        font-size: 1.7rem;
        font-weight: 500;
        span {
          color: $color-orange;
        }
      }
      p {
        margin: 20px 0 20px 0;
        font-size: 1.1rem;
      }
    }
    &--logos {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        li {
          margin-top: 1rem;
          @include max-sm{
            width: 50%;
          }
        }
      }
    }
  }

  //cta
  .cta-integracoes {
    img {
      box-shadow: $box-shadow;
      border-radius: 50px;
    }
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(248,249,250,1) 50%);
    .absolute {
      left: 50%;
      top: 28%;
      margin: 0;
      transform: translate(-50%, -50%);
      @include min-md {
        top: 50%;
      }
      @include min-lg {
        left: 22%;
        top: 40%;
      }
    }
    &--text {
      padding: 0 1rem;
      h3 {
        text-align: center;
        color: white;
        font-size: 30px;
        font-weight: 800;
        display: inline-block;
        span {
          background-color: $color-orange;
          padding: 10px;
        }
        @include min-md {
          font-size: 24px
        }
        @include min-lg {
          font-size: 40px;
        }
      }
      p {
        color: white;
        font-size: 18px;
        line-height: 1.4;
        margin: 20px 0 20px 0;
        @include min-md {
          font-size: 18px
        }
        @include min-lg {
          font-size: 24px;
        }
      }
      @include min-md {
        padding-right: 24rem;
      }
      @include min-lg {
        padding: 0;
      }
    }
  }

  //app de vendas
  .app-integracoes {
    padding: 7rem 0;
    img {
      margin: 0 auto;
    }
    @include max-sm {
      img {
        width: 75%;
      }
    }
    .block-info--text {
      margin-bottom: 4rem;
      h2 {
        line-height: 1.4;
        span {
          font-weight: 700;
        }
      }
      p {
        margin: 2rem 0 1rem 0;
      }
      .btn-app {
        background-color: #fff;
        font-weight: 500;
        font-size: 1rem;
        text-transform:none;
        border: solid 1px $color-0;
        color: $color-0;
        margin-top: 24px;
        width: 100%;
        padding: 15px 20px;
        &:hover {
          background-color: #ededed;
        }
        @include min-lg {
          width: 340px;
        }
      }
      @include min-lg {
        padding-right: 2rem;
        margin-bottom: 0;
      }
    }
  }

  //setores
  .block-setores {
    .block-info {
      &--text {
        span {
          font-weight: 700;
        }
      }
      &--logos {
        ul {
          margin-top: 2rem;
          a {
            display: block;
            width: 48%;
            @include min-lg {
              width: 24%;
            }
            li {
              background-color: #fff;
              border-radius: 10px;
              margin: .2rem;
              text-align: center;
              padding: 2rem;
              transition: $transition;
              width: 100%;
              &:hover {
                box-shadow: 5px 5px 15px 0 rgb(0 0 0 / 5%);
                transform: scale(.95);
              }
              img {
                margin: 0 auto;
              }
              p {
                margin-top: 1rem;
              }
            }
          }
        }
      }
    }
  }

  //cta 2
  .cta-sistema {
    padding: 5rem 0;
    color: white;
    background: $color-dark;
    &--text {
      margin-top: 2rem;
    }
    img {
      margin: 0 auto;
    }
    h2 {
      font-size: 1.7rem;
      color: $color-orange;
      font-weight: 700;
    }
    p {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    @include min-md {
      background: url(#{$imagePath}/layout/background-cta.webp);
      background-repeat: no-repeat;
      background-position: center;
      background-size:cover;
    }
  }
}