.slick {
  &-list {
    padding-bottom: 10px;
    .slick-loading & {
        background: #fff slick-image-url('#{$imagePath}/ajax-loader.gif') center center no-repeat;
    }
  }
}

//Slide padrão
.slick-skin {
  //overflow: hidden;
  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
  }
  .slick-prev:before, .slick-next:before {
    font-family: $font-icons;
    font-size: 2.4rem;
    line-height: 1;
    color: $black;
  }
  .slick {
    &-prev {
      left: 0;
      &:hover {opacity: .7;}
      &::before {content: '\e5cb';}
    }
    &-next {
      right: 0;
      &:hover {opacity: .7;}
      &::before {content: '\e5cc';}
    }
  }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    display: block;
    width: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      width: inherit;
      height: inherit;
      margin: 0 4px;
      padding: 0;
      cursor: pointer;
        button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 1rem;
          height: 1rem;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: none; //border: solid 1px #222;
          border-radius: 20px;
          outline: none;
          background: transparent;
          border: 1px solid #ddd;
          &:hover,
          &:focus {
            outline: none;
          }
        }
      }
    .slick-active {
      button {
        background: #ccc;
      }
    }
  }
  .slick-list {
    padding: 0;
  }
  .slick-dots {
    position: relative;
    display: block;
    //width: 200px;
    //left: 50%;
    //margin-left: -100px;
    >li {
      width: initial;
      height: inherit;
      margin: 0 3px;
      &:not(.slick-active) {
        button {
          &:hover {
            opacity: 1;
          }
        }
      }
      button {
        width: 1rem;
        height: 1rem;
        padding: 0;
        border: solid 1px #fff;
        border-radius: 20px;
        background: #ccc;
      }
    }
    .slick-active {
      button {
        background: $orange;
        &:hover {
          cursor: default;
        }
      }
    }
  }
}

//Slide clientes principal
.clients {
  @extend .slick-skin;
  padding: 0 2rem;
  &-item {
    text-align: center;
    padding: 5px;
  }
  &-img {
    display: inherit;
    margin: 0 auto;
    transition: all .25s ease-in-out;
    filter: grayscale(1);
    &:hover {
      filter: grayscale(0)
    }
  }
  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 60px;
  }
}

//Slide standard
.standard-slide {
  @extend .slick-skin;
  .slick{
    &-slide{
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      width: 100%;
      height: auto;
      margin: 0 10px;
      padding: 30px 0;
    }
    &-prev, &-next {
      z-index: 2;
      color: $white;
      background: #F59475;
      border-radius: 50%;
      width: 2.4rem;
      height: 2.4rem;
      display: inline-block;
    }
  }
  &-width {
    @include min-md {
      width: 28rem;
      padding: 0 2rem;
    }
  }
}