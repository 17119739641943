.CardLink {
  display: block;
}
.CardBig {
  position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 10px 10px 20px 5px rgba(0,0,0,0.1);
  border-radius: 20px;
  text-align: center;
 	padding: 20px;
  height: 360px;
  width: 300px;
  margin-bottom: 50px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .3s ease;
  @include min-lg {
    height: 500px;
    width: 400px;
    padding: 50px 30px;
    margin: 0 auto 40px;
  }
  &:hover {
    transform: scale(.98);
  }
  &-image {
    width: 50%;
    margin: 0 auto;
  }
	&-title {
		font-size: 1.5rem;
    padding: 0 1rem;
    font-weight: 600;
		color: $orange;
		line-height: 1.3;
	}
  &-text {
    color: #fff;
    margin-top: 1rem;
    @include min-lg {
      padding: 0 4rem;
    }
  }
	&-button {
		background: $color-1;
		width: 50px;
		height: 50px;
		display: inline-block;
		position: absolute;
		border-radius: 50px;
		color: #fff;
		font-size: 36px;
		line-height: 1.4;
		font-weight: 700;
		bottom: -25px;
		margin-left: -25px;
		left: 50%;
		 transition: ease all 0.3s;
		&:hover {
			color: #fff;
		}
	}
  &__industria {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(#{$imagePath}/layout/card-industria.webp);
  }
  &__distribuidora {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(#{$imagePath}/layout/card-distribuidora.webp);
  }
}